import React from 'react'
import { useSelector } from 'react-redux'
import { imageSizeUrl, addSlashInternalLink } from '../../helper'

const QuoteBlock = ({ quoteText, avatar, name, detail }) => {
     const avatarImage = imageSizeUrl('medium', avatar.mediaDetails.sizes, avatar.mediaItemUrl)
     const darkMode = useSelector(state => state.theme.darkModeRedux)
     return (
          <div className="quote-wrapper">
               {quoteText && (
                    <div className="quote">
                         <div className="quote-icon-start">
                              <img src={darkMode ? `/images/quote-start-dark-mode.svg` : `/images/quote-start.svg`} alt="Quote Start Icon" />
                         </div>
                         <div className="quote-content">
                              <p>
                                   {quoteText}
                                   <div className="quote-icon-end">
                                        <img src={darkMode ? `/images/quote-end-dark-mode.svg` : `/images/quote-end.svg`} alt="Quote End Icon" />
                                   </div>
                              </p>
                         </div>
                    </div>
               )}
               <div className="founder">
                    {avatar && <div className="avatar-image" style={{ backgroundImage: `url(${avatarImage})` }}></div>}

                    <div className="infor">
                         {name && (
                              <div className="name">
                                   <p>{name}</p>
                              </div>
                         )}
                         {detail && (
                              <div className="detail">
                                   <p dangerouslySetInnerHTML={{ __html: addSlashInternalLink(detail) }}></p>
                              </div>
                         )}
                    </div>
               </div>
          </div>
     )
}

export default QuoteBlock
