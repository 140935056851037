import React from 'react'
import { logoType, addSlashButtonLink } from '../../helper'

const ListenersIntegrationBlock = ({ buttonBrowse, listeners }) => {
    let slugListenAll = addSlashButtonLink(buttonBrowse.url);
    return (
        <div className="browse-all-apps-wrapper">
            <div className="browse-all-apps">
                <div className="app-row">
                    {
                        listeners.map((listener, index) => {
                            const logo = logoType(listener.cfListener.mainElements.logoFileSvg,
                                listener.cfListener.mainElements.logoFileImage,
                                listener.cfListener.mainElements.useImageInsteadOfSvg)
                            const name = listener.cfListener.mainElements.listenerNamePlural
                            return (
                                <div key={index} className="app-col">
                                    <a href={`${slugListenAll + listener.cfListener.mainElements.slug}/`} target={buttonBrowse.target ? buttonBrowse.target : '_self'} className="app-item">
                                        <div className="logo">
                                            {logo}
                                        </div>
                                        <div className="text">
                                            <span>{name}</span>
                                        </div>
                                    </a>
                                </div>
                            )
                        })
                    }
                    {
                        buttonBrowse && buttonBrowse.title && (
                            <div className="app-col">
                                <a href={slugListenAll} target={buttonBrowse.target ? buttonBrowse.target : '_self'} className="all-app-item">
                                    <div className="text">
                                        <span>{buttonBrowse.title}</span>
                                    </div>
                                </a>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ListenersIntegrationBlock
