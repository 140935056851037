import React from 'react'

const WysiwygBlock = () => {
    return (
        <div>
            Wysiwyg
        </div>
    )
}

export default WysiwygBlock
