import React from 'react'
// import CCPARequests from './CCPARequests'
import DataRequests from './DataRequests'

const FormBlock = ({ formType }) => {
     const getFormComponent = () => {
          switch (formType) {
               case 'CCPA Requests':
                    return <DataRequests />
               default:
                    return
          }
     }

     return <div className="form-block">{getFormComponent()}</div>
}

export default FormBlock
