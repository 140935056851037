import React from 'react'
import CreateAccountBlock from '../Blocks/CreateAccountBlock'
import NewCreateAccountBlock from '../Blocks/NewCreateAccountBlock'
import HeadlineTypeBlock from '../Blocks/HeadlineTypeBlock'

const VideoFullWidth = (values) => {
    const { headline, headlineType, description, videoEmbedCode, addCreateAccount, createAccountFields } = values
    return (
        <div className={`video-embed-code ${headline ? 'headline' : ''} ${addCreateAccount ? 'have-form' : ''}`}>
            {
                addCreateAccount && (
                    <CreateAccountBlock
                        createAccountType={createAccountFields.createAccountType}
                    />
                )
            }
            {
                headline && (
                    <div className="headline">
                        <HeadlineTypeBlock headline={headline} headlineType={headlineType} />
                        {
                            description && (
                                <div className="description">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <p>{description}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
            <div className="video-embed-code-iframe">
                <div dangerouslySetInnerHTML={{ __html: videoEmbedCode }}></div>
            </div>
        </div>
    )
}

const VideoTwoCol = (values) => {
    const { headline, headlineType, description, videoEmbedCode, addCreateAccount, createAccountFields } = values
    return (
        <div className={`video-embed-code two-col ${headline ? 'headline' : ''} ${addCreateAccount ? 'have-form' : ''}`}>
            {
                headline && (
                    <div className="headline">
                        <HeadlineTypeBlock headline={headline} headlineType={headlineType} />
                        {
                            description && (
                                <div className="description">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <p>{description}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
            <div className={`wrapper ${addCreateAccount ? '' : 'no-form'}`}>
                <div className="row">
                    <div className="video">
                        <div className="video-embed-code-iframe">
                            <div dangerouslySetInnerHTML={{ __html: videoEmbedCode }}></div>

                        </div>
                    </div>
                    {
                        addCreateAccount && createAccountFields.createAccountTypeTwoColumn === "New Smaller Version" && (
                            <div className="create-account">
                                <NewCreateAccountBlock />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

const VideoBlock = (values) => {
    let content = ''
    switch (values.layout) {
        case 'Two Column':
            content = <VideoTwoCol {...values} />
            break;
        default:
            content = <VideoFullWidth {...values} />
    }
    if (values.blockId) {
        return (
            <div id={values.blockId} className="video-embed-id" >
                {content}
            </div>
        )
    }
    return (
        <>
            {content}
        </>
    )
}
export default VideoBlock
