import React, { Fragment } from 'react'

export const AdvantageBlock = () => {
     const blocks = [
          {
               title: 'It’s your data… Own it!',
               description: `<p>ListenLayer does not sell or use your data for our own benefit (unlike those "free" analytics tools).</p><p>Your data belongs to you - now you have the freedom to use it how you've always wanted.</p>`,
               highlight: true,
          },
          {
               title: 'Security-first Architecture',
               description: `<p>We take the security of your data seriously, with full encryption, access controls, and proactive measures.</p><p>ListenLayer is currently pursuing SOC2, Type 2 compliance to reflect our commitment to security.</p>`,
          },
          {
               title: 'Speed Matters',
               description: `<p>Improve your website's speed and SEO scores by implementing server side tagging and loading remaining libraries over your own domain...</p><p>ListenLayer enables both, easily!</p>`,
          },
          {
               title: 'Privacy Compliance',
               description:
                    '<p>How we identify users and store data enables compliance with ever-changing privacy laws.</p><p>Our architecture supports GDPR, CCPA/CPRA, and other regulatory requirements, and ListenLayer can be used to completely replace (or augment) other Consent Managment Platforms.</p>',
          },
     ]
     return (
          <>
               <div className={`advantage-wrapper`}>
                    {blocks &&
                         blocks.map((block, index) => {
                              const { title, description } = block
                              if (title && description) {
                                   return (
                                        <div key={index} className="advantage-item">
                                             <h4 className={`advantage-title ${block.highlight ? 'highlight' : ''}`}>{title}</h4>
                                             <div className="advantage-description" dangerouslySetInnerHTML={{ __html: description }} />
                                        </div>
                                   )
                              }
                              return <Fragment key={index}></Fragment>
                         })}
               </div>
          </>
     )
}

export default AdvantageBlock
