import { useStaticQuery, graphql } from "gatsby"

export const useListeners = () => {
    const { allWpCptListener, allWpTaxListenerFilterType } = useStaticQuery(
        graphql`
            query Listeners {
                allWpTaxListenerFilterType {
                    edges {
                        node {
                            slug
                            name
                            cptListeners {
                                nodes{
                                    title
                                }
                            }
                        }
                    }
                }
                allWpCptListener {
                    nodes {
                        ...WordpressCPTListenerRelatedFields
                    }
                }
            }
    `
    )
    return { allWpCptListener, allWpTaxListenerFilterType };
}
