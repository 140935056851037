import React, { useState, useRef, Fragment } from 'react'
import UseCaseBlock from './UseCaseBlock'
import { getSlug, replaceStr } from '../../helper'
import { useUseCases } from '../../hooks/useUseCases'
import SearchUseCaseBlock from '../Blocks/search-block/SearchUseCaseBlock'
import { useLoadMore } from '../../hooks/useLoadMore'
import { USECASE_SHORTCODE } from '../../constants'
import HeadlineTypeBlock from './HeadlineTypeBlock'
import CreateAccountBlock from './CreateAccountBlock'
import { Link } from 'gatsby'

const ShowAllUseCaseBlock = ({ headline, headlineType, headlineSearch, usecasesPost, isUseCaseTypePage }) => {
    const loader = useRef(null)
    const { allWpCptUseCase, allWpTaxUseCaseType } = useUseCases()
    const usecaseTypes = allWpTaxUseCaseType.edges
    const useCases = usecasesPost ? usecasesPost : allWpCptUseCase.nodes
    const numberUseCasesLoad = 6
    const totalUseCases = useCases.length
    const [numberUseCasesCurrent, setNumberUseCasesCurrent] = useState(numberUseCasesLoad)
    const currentUseCases = useCases.slice(0, numberUseCasesCurrent)
    const usecaseSlug = getSlug(useCases[0].uri)
    let createAccountUnLoad = totalUseCases < numberUseCasesLoad ? <div className="col-12"><CreateAccountBlock /></div> : ''

    useLoadMore({ loader, numberCurrent: numberUseCasesCurrent, numberLoad: numberUseCasesLoad, setNumberCurrent: setNumberUseCasesCurrent })

    return (
        <div className="use-cases-wrapper new">
            <div className="headline">
                <HeadlineTypeBlock headline={headline} headlineType={headlineType} />
            </div>
            <div id="showAllUseCase" className="search-usecase">
                <SearchUseCaseBlock headline={headlineSearch ? headlineSearch : `Search for use cases`} />
            </div>
            <div className="tab-wrapper">
                <div className="tab-item">
                    <Link className="button" to={isUseCaseTypePage ? `/${usecaseSlug}/#showAllUseCase` : `/${usecaseSlug}/`} activeClassName="active" >SHOW ALL</Link>
                </div>
                {
                    usecaseTypes && usecaseTypes.map((usecaseType, index) => {
                        if (usecaseType.node.cptUseCases.nodes.length) {
                            return (
                                <div key={index} className="tab-item">
                                    <Link className="button" to={`/${usecaseSlug}/${usecaseType.node.slug}/`} activeClassName="active">{usecaseType.node.name}</Link>
                                </div>
                            )
                        } else {
                            return (
                                <Fragment key={index}></Fragment>
                            )
                        }
                    })
                }
            </div>
            <div className="row">
                {
                    currentUseCases && currentUseCases.map((usecase, index) => {
                        const usecaseName = [usecase.cfUseCase.mainElements.usecaseNamePlural, usecase.cfUseCase.mainElements.usecaseNameSingular]
                        let createAccountLoad = ''

                        if (index === 5) {
                            createAccountLoad = <div className="col-12"><CreateAccountBlock /></div>
                        }
                        return (
                            <Fragment key={index}>
                                <div className="col-md-6">
                                    <UseCaseBlock
                                        imageExcerpt={usecase.cfUseCase.mainElements.excerptImage}
                                        title={replaceStr(
                                            USECASE_SHORTCODE,
                                            usecaseName,
                                            usecase.cfUseCase.mainElements.excerptTitle || usecase.cfUseCase.mainElements.h1
                                        )}
                                        excerpt={replaceStr(USECASE_SHORTCODE, usecaseName, usecase.cfUseCase.mainElements.useCaseExcerpt)}
                                        usecaseSlug={`/${usecaseSlug}/${usecase.cfUseCase.mainElements.slug}/`}
                                    />
                                </div>
                                {createAccountLoad && createAccountLoad}
                            </Fragment>
                        )
                    })
                }
                {createAccountUnLoad && createAccountUnLoad}
            </div>
            {
                numberUseCasesCurrent < totalUseCases && (
                    <div className="load-more" ref={loader}>
                        <span>Loading more...</span>
                    </div>
                )
            }
        </div>
    )
}

export default ShowAllUseCaseBlock
