import React, { Fragment } from 'react'

const HowItWorkV2Block = ({ openingContent, explainContent, steps }) => {
    return (
        <div className="how-it-work-v2-wrapper">
            <div className='how-it-work-v2-opening-content'>
                {openingContent && <div dangerouslySetInnerHTML={{ __html: openingContent }} />}
                {explainContent && <div className='how-it-work-v2-explain-content' dangerouslySetInnerHTML={{ __html: explainContent }} />}
            </div>
            {steps && (
                <div className='how-it-work-v2-steps'>
                    {steps.map((block, index) => {
                        const { icon, title, description } = block;

                        if (icon) {
                            return (
                                <div key={index} className="how-it-work-v2-step" style={{ '--step': index + 1 }}>
                                    <img class="line-arrow-first is-mobile" src="/images/arrow-line.png" alt="Line Arrow First" />
                                    {icon && (
                                        <div className="how-it-work-v2-step-icon" dangerouslySetInnerHTML={{ __html: icon }} />
                                    )}
                                    <div className='how-it-work-v2-step-content'>
                                        {title && <h4 className='how-it-work-v2-step-title'>{title}</h4>}
                                        {description && <div className='how-it-work-v2-step-description' dangerouslySetInnerHTML={{ __html: description }} />}
                                    </div>
                                </div>
                            )
                        }
                        return (<Fragment key={index}></Fragment>)
                    })}
                </div>
            )}
        </div>
    )
}

export default HowItWorkV2Block
