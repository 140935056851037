// import { navigate } from '@reach/router'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import { useCalendlyEventListener, InlineWidget } from 'react-calendly'

const PopupSchedule = ({ showSchedule, setShowSchedule }) => {
     // const [showSchedule, setShowSchedule] = useState(false)
     // const handleShowSchedule = () => setShowSchedule(true)

     const [isLoading, setIsLoading] = useState(true);
     const [isSuccess, setIsSuccess] = useState(false);

     const updateUrl = () => {
          if (window.location.href.includes('?view-demo=1&')) {
               let newURL = window.location.href.replace('?view-demo=1&', '?');
               window.history.replaceState(null, null, newURL);
          } else {
               let updatedURL = window.location.href.replace(/(\?|\&)view-demo=1/g, '');
               window.history.replaceState(null, null, updatedURL);
          }
     }

     const handleCloseSchedule = () => {
          setShowSchedule(false);
          setIsSuccess(false);
          updateUrl();
     }

     const clickThankSchedule = () => {
          setShowSchedule(false);
          setIsSuccess(false);
          updateUrl();
     }

     useCalendlyEventListener({
          onEventTypeViewed: () => setIsLoading(false),
          onEventScheduled: e => {
               if (e.data.event) {
                    // toast.success('Schedule a Talk to Sales Successful')
                    setIsSuccess(true)
               }
          },
     })

     return (
          <>
               <Modal className="scheduleDemo" show={showSchedule} onHide={handleCloseSchedule} animation={false} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                         {isSuccess ? (
                              <div className="section-thankyou">
                                   <h3>Thank you!</h3>
                                   <p>
                                        Your meeting has been schedule. Check you email, an invitation was sent with details and will be added to your
                                        canlendar
                                   </p>
                                   <div className="button-wrapper">
                                        <button onClick={clickThankSchedule}>Go back</button>
                                   </div>
                              </div>
                         ) : (
                              <div className="section-schedule">
                                   <h3>Schedule a Demo</h3>
                                   <p className="des">
                                        Grab a time below for an initial 15-minute discovery call. Then we'll schedule a custom demo specifically for
                                        you.
                                   </p>
                                   {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                                   <InlineWidget
                                        url="https://calendly.com/listenlayer-rick/schedule-a-demo"
                                        styles={{
                                             height: '1000px',
                                        }}
                                   />
                              </div>
                         )}
                    </Modal.Body>
               </Modal>
          </>
     )
}

export const HowToGetStartedBlock = () => {
     const [showSchedule, setShowSchedule] = useState(false)
     const handleShowSchedule = () => {
          setShowSchedule(true);
          if(window.location.href.includes("&")){
               let newUrl = window.location.href + '&view-demo=1'
               window.history.replaceState(null, null, newUrl);
          }else {
               window.history.replaceState(null, null, '?view-demo=1');
          }
     }

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes("view-demo=1")) {
               setShowSchedule(true);
          }
     }, [showSchedule])

     return (
          <>
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <div className="get-started-wrapper">
                    <div className="get-started-quote">
                         <div className="get-started-quote-avatar">
                              <img src="/images/mark-twain.png" alt="Mark Twain" />
                         </div>
                         <div className="get-started-quote-content">
                              <span className="meta">Mark Twain:</span>
                              <div className="content">
                                   <p>The secret of getting ahead is getting started</p>
                              </div>
                         </div>
                         <div className="get-started-quote-link-arrow">
                              <img src="/images/link-arrow.png" alt="link-arrow" />
                         </div>
                    </div>
                    <div className="get-started-headline">
                         <h3>How to get started</h3>
                         <p>
                              ListenLayer is built, and ready for enterprise use. But our goal is to be available to companies of all sizes. Here's
                              how you can get started.
                         </p>
                    </div>
                    <div className="get-started-list">
                         <div className="get-started-block">
                              <h4 className="title">Schedule a Demo</h4>
                              <div className="description">
                                   <p>
                                        <button className="btn-link" type="button" onClick={() => handleShowSchedule()}>
                                             Schedule Demo
                                        </button>{' '}
                                        for an in-depth review of the platform. We offer implementation & managed services, and our support team is
                                        world class!
                                   </p>
                              </div>
                         </div>
                         <div className="get-started-block">
                              <h4 className="title">Try ListenLayer Now</h4>
                              <div className="description">
                                   <p>
                                        Start using ListenLayer today by{' '}
                                        <a href={`${process.env.GATSBY_APP_PATH}/register`} target="_blank" rel="noreferrer">
                                             creating an account
                                        </a>
                                        . Our platform is designed for self serve, and comes with world class support.
                                   </p>
                              </div>
                         </div>
                         <div className="get-started-block">
                              <h4 className="title">Learn More</h4>
                              <div className="description">
                                   <p>
                                        Not ready to dive in? Learn more about <Link to="/platform/">the platform</Link>,{' '}
                                        <Link to="/outcomes/" style={{ color: '#4DBD74' }}>
                                             explore outcomes
                                        </Link>
                                        , or review our{' '}
                                        <Link to="/use-cases/" style={{ color: '#1A6DAF' }}>
                                             use cases
                                        </Link>
                                        .
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default HowToGetStartedBlock
