import React from 'react'
import ListenerListBlock from './ListenerListBlock'
import { getSlug } from '../../helper'
import { useListeners } from '../../hooks/useListeners'

const ShowAllListenerBlock = ({ headlineSearch, subHeadlineSearch, headlineRelated, showHeadlineAndBody, headlineAndBody }) => {
     const { allWpCptListener, allWpTaxListenerFilterType } = useListeners()
     const listenerSlug = getSlug(allWpCptListener.nodes[0].uri)

     return (
          <div className="listener-type-related">
               <ListenerListBlock
                    headlineSearch={headlineSearch}
                    subHeadlineSearch={subHeadlineSearch}
                    headlineRelated={headlineRelated}
                    listenerSlug={listenerSlug}
                    relatedListener={allWpCptListener.nodes}
                    listenerFilterTypes={allWpTaxListenerFilterType.edges}
                    showHeadlineAndBody={showHeadlineAndBody}
                    HeadlineAndBodyTextBlock={headlineAndBody}
               />
          </div>
     )
}

export default ShowAllListenerBlock
