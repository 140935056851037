import { useStaticQuery, graphql } from "gatsby"

export const useUseCases = () => {
    const data = useStaticQuery(graphql`
        query UseCases {
            allWpTaxUseCaseType {
                edges {
                    node {
                        slug
                        name
                        cptUseCases {
                            nodes {
                                title
                            }
                        }
                    }
                }
            }
            allWpCptUseCase {
                nodes {
                    ...WordpressCPTUseCaseRelatedFields
                }
            }
        }
    `)
    return data;
}
