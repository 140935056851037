import React, { useState, Fragment, useRef } from 'react'
import RelatedListenerBlock from './related-block/RelatedListenerBlock'
import SearchListenerBlock from './search-block/SearchListenerBlock'
import { Link } from 'gatsby'
import Spinner from 'react-bootstrap/Spinner'
import { useLoadMore } from '../../hooks/useLoadMore'

const ListenerListBlock = ({
     headlineSearch,
     subHeadlineSearch,
     headlineRelated,
     relatedListener,
     listenerSlug,
     listenerFilterTypes,
     showHeadlineAndBody,
     HeadlineAndBodyTextBlock,
}) => {
     const numberListenersLoad = 12
     const totalListeners = relatedListener.length
     const [numberListenersCurrent, setNumberListenersCurrent] = useState(numberListenersLoad)
     const currentListeners = relatedListener.slice(0, numberListenersCurrent)
     const headlineAndBodyText = showHeadlineAndBody ? HeadlineAndBodyTextBlock : ''
     const loader = useRef(null)

     useLoadMore({ loader, numberCurrent: numberListenersCurrent, numberLoad: 4, setNumberCurrent: setNumberListenersCurrent })

     //  Check active show all on page Automate Marketing Data
     const listenerSlugHashCode = typeof window !== 'undefined' ? window.location.pathname : ''
     const flagPageAutomate = listenerSlugHashCode === '/platform/automate-marketing-data/'

     return (
          <div>
               <div className="search-listener">
                    <SearchListenerBlock headline={headlineSearch} subHeadline={subHeadlineSearch} />
               </div>
               <div className="tab-wrapper">
                    <div className="tab-item">
                         <Link className={`button ${flagPageAutomate ? 'active' : ''}`} to={`/${listenerSlug}/`} activeClassName="active">
                              SHOW ALL
                         </Link>
                    </div>
                    {listenerFilterTypes &&
                         listenerFilterTypes.map((listenerType, index) => {
                              if (listenerType.node.cptListeners.nodes.length) {
                                   return (
                                        <div key={index} className="tab-item">
                                             <Link className="button" to={`/${listenerSlug}/${listenerType.node.slug}/`} activeClassName="active">
                                                  {listenerType.node.name}
                                             </Link>
                                        </div>
                                   )
                              } else {
                                   return <Fragment key={index}></Fragment>
                              }
                         })}
               </div>
               <div className="listeners-related-wrapper">
                    <RelatedListenerBlock titleH3={headlineRelated} relatedListeners={currentListeners} headlineAndBodyText={headlineAndBodyText} />
                    {numberListenersCurrent < totalListeners && (
                         <div className="load-more" ref={loader}>
                              Loading More
                              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="ml-1" />
                         </div>
                    )}
               </div>
          </div>
     )
}

export default ListenerListBlock
