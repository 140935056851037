import React from 'react'
import Block from './Blocks'
import { addSlashInternalLink } from '../helper'

const SectionGridDefault = ({ section, spaceBelow, spaceAbove, fullWidthTextAboveColumns, addCustomWidthContainer, customWidthContainer }) => {
    if (section) {
        const col0 = typeof section[0] != 'undefined' ? section[0] : null
        if (addCustomWidthContainer && customWidthContainer) {
            return (
                <section className={`${spaceBelow} ${spaceAbove}`}>
                    {
                        fullWidthTextAboveColumns && (
                            <div className="full-width-text-above">
                                <div className="container" style={{ maxWidth: `${customWidthContainer}px` }}>
                                    <div className="content" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(fullWidthTextAboveColumns) }}></div>
                                </div>
                            </div>
                        )
                    }
                    <div className="container" style={{ maxWidth: `${customWidthContainer}px` }}>
                        {col0 != null && (
                            <Block block={col0}></Block>
                        )}
                    </div>
                </section>
            )
        }
        return (
            <section className={`${spaceBelow} ${spaceAbove}`} >
                {
                    fullWidthTextAboveColumns && (
                        <div className="full-width-text-above">
                            <div className="container">
                                <div className="content" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(fullWidthTextAboveColumns) }}></div>
                            </div>
                        </div>
                    )
                }
                <div className="container">
                    {col0 != null && (
                        <Block block={col0}></Block>
                    )}
                </div>
            </section>
        )
    } else {
        return (
            <></>
        )
    }
}

const SectionGrid2Column = ({ section, spaceBelow, spaceAbove, addBackground, addCustomWidthContainer, customWidthContainer }) => {
    if (section) {
        const col0 = typeof section[0] != 'undefined' ? section[0] : null
        const col1 = typeof section[1] != 'undefined' ? section[1] : null
        if (addCustomWidthContainer && customWidthContainer) {
            return (
                <section className={`${spaceBelow} ${spaceAbove} ${addBackground ? 'superpowers' : ''}`} >
                    <div className="container" style={{ maxWidth: `${customWidthContainer}px` }} >
                        <div className="row">
                            {col0 != null && (
                                <div className="col-lg-6">
                                    <Block block={col0}></Block>
                                </div>
                            )}
                            {col1 != null && (
                                <div className="col-lg-6">
                                    <Block block={col1}></Block>
                                </div>
                            )}
                        </div>
                    </div>
                </section >
            )
        }
        return (
            <section className={`${spaceBelow} ${spaceAbove} ${addBackground ? 'superpowers' : ''}`}>
                <div className="container">
                    <div className="row">
                        {col0 != null && (
                            <div className="col-lg-6">
                                <Block block={col0}></Block>
                            </div>
                        )}
                        {col1 != null && (
                            <div className="col-lg-6">
                                <Block block={col1}></Block>
                            </div>
                        )}
                    </div>
                </div>
            </section >
        )
    } else {
        return (
            <></>
        )
    }
}

const SectionGrid2Column7 = ({ section, spaceAbove, spaceBelow, addCustomWidthContainer, customWidthContainer }) => {
    if (section) {
        const col0 = typeof section[0] != 'undefined' ? section[0] : null
        const col1 = typeof section[1] != 'undefined' ? section[1] : null
        if (addCustomWidthContainer && customWidthContainer) {
            return (
                <section className={`${spaceBelow} ${spaceAbove}`} >
                    <div className="container" style={{ maxWidth: `${customWidthContainer}px` }}>
                        <div className="row">
                            {col0 != null && (
                                <div className="col-lg-7">
                                    <Block block={col0}></Block>
                                </div>
                            )}
                            {col1 != null && (
                                <div className="col-lg-5">
                                    <Block block={col1}></Block>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            )
        }
        return (
            <section className={`${spaceBelow} ${spaceAbove}`}>
                <div className="container">
                    <div className="row">
                        {col0 != null && (
                            <div className="col-lg-7">
                                <Block block={col0}></Block>
                            </div>
                        )}
                        {col1 != null && (
                            <div className="col-lg-5">
                                <Block block={col1}></Block>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <></>
        )
    }
}

const SectionGrid10Column2 = ({ section, spaceAbove, spaceBelow, addCustomWidthContainer, customWidthContainer }) => {
    if (section) {
        const col0 = typeof section[0] != 'undefined' ? section[0] : null
        const col1 = typeof section[1] != 'undefined' ? section[1] : null
        if (addCustomWidthContainer && customWidthContainer) {
            return (
                <section className={`${spaceBelow} ${spaceAbove}`} >
                    <div className="container" style={{ maxWidth: `${customWidthContainer}px` }}>
                        <div className="row">
                            {col0 != null && (
                                <div className="col-lg-10">
                                    <Block block={col0}></Block>
                                </div>
                            )}
                            {col1 != null && (
                                <div className="col-lg-2">
                                    <Block block={col1}></Block>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            )
        }
        return (
            <section className={`${spaceBelow} ${spaceAbove}`}  >
                <div className="container">
                    <div className="row">
                        {col0 != null && (
                            <div className="col-lg-10">
                                <Block block={col0}></Block>
                            </div>
                        )}
                        {col1 != null && (
                            <div className="col-lg-2">
                                <Block block={col1}></Block>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <></>
        )
    }
}

const SectionGrid8Column4 = ({ section, spaceAbove, spaceBelow, addCustomWidthContainer, customWidthContainer }) => {
    if (section) {
        const col0 = typeof section[0] != 'undefined' ? section[0] : null
        const col1 = typeof section[1] != 'undefined' ? section[1] : null
        if (addCustomWidthContainer && customWidthContainer) {
            return (
                <section className={`${spaceBelow} ${spaceAbove}`} >
                    <div className="container" style={{ maxWidth: `${customWidthContainer}px` }}>
                        <div className="row">
                            {col0 != null && (
                                <div className="col-lg-8">
                                    <Block block={col0}></Block>
                                </div>
                            )}
                            {col1 != null && (
                                <div className="col-lg-4">
                                    <Block block={col1}></Block>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            )
        }
        return (
            <section className={`${spaceBelow} ${spaceAbove}`}>
                <div className="container">
                    <div className="row">
                        {col0 != null && (
                            <div className="col-lg-8">
                                <Block block={col0}></Block>
                            </div>
                        )}
                        {col1 != null && (
                            <div className="col-lg-4">
                                <Block block={col1}></Block>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <></>
        )
    }
}

const Section = ({ sectionValues }) => {
    const { section, showSection } = sectionValues
    const { cfSection } = section[0]
    const {
        fullWidthBlocks,
        twoColumns70And50,
        twoColumns50And50,
        twoColumns100And20,
        twoColumns80And40,
        layout,
        addBackgroundFullWidth,
        fullWidthTextAboveColumns,
        addCustomWidthContainer,
        customWidthContainer
    } = cfSection
    let content = ''
    let spaceAbove = ''
    let spaceBelow = ''
    if (showSection === 'Yes') {
        switch (cfSection.sectionSettings.spaceAbove) {
            case 'Small':
                spaceAbove = 'space-above-small'
                break;
            case 'Medium':
                spaceAbove = 'space-above-medium'
                break;
            case 'Large':
                spaceAbove = 'space-above-large'
                break;
            default:
                spaceAbove = 'space-above-0'
                break;
        }

        switch (cfSection.sectionSettings.spaceBelow) {
            case 'Small':
                spaceBelow = 'space-below-small'
                break;
            case 'Medium':
                spaceBelow = 'space-below-medium'
                break;
            case 'Large':
                spaceBelow = 'space-below-large'
                break;
            default:
                spaceBelow = 'space-below-0'
                break;
        }

        switch (layout) {
            case '2-columns (60/60)':
                content = <SectionGrid2Column
                    section={twoColumns50And50}
                    spaceBelow={spaceBelow}
                    spaceAbove={spaceAbove}
                    addBackground={addBackgroundFullWidth}
                    addCustomWidthContainer={addCustomWidthContainer}
                    customWidthContainer={customWidthContainer}
                />
                break;
            case '2-columns (70/50)':
                content = <SectionGrid2Column7
                    section={twoColumns70And50}
                    spaceBelow={spaceBelow}
                    spaceAbove={spaceAbove}
                    addCustomWidthContainer={addCustomWidthContainer}
                    customWidthContainer={customWidthContainer}
                />
                break;
            case '2-columns (100/20)':
                content = <SectionGrid10Column2
                    section={twoColumns100And20}
                    spaceBelow={spaceBelow}
                    spaceAbove={spaceAbove}
                    addCustomWidthContainer={addCustomWidthContainer}
                    customWidthContainer={customWidthContainer}
                />
                break;
            case '2-columns (80/40)':
                content = <SectionGrid8Column4
                    section={twoColumns80And40}
                    spaceBelow={spaceBelow}
                    spaceAbove={spaceAbove}
                    addCustomWidthContainer={addCustomWidthContainer}
                    customWidthContainer={customWidthContainer}
                />
                break;
            default:
                content = <SectionGridDefault
                    section={fullWidthBlocks}
                    spaceBelow={spaceBelow}
                    spaceAbove={spaceAbove}
                    fullWidthTextAboveColumns={fullWidthTextAboveColumns}
                    addCustomWidthContainer={addCustomWidthContainer}
                    customWidthContainer={customWidthContainer}
                />
        }
    }

    return (
        content && (
            <>
                {content}
            </>
        )
    )

}

export default Section
