import React from 'react'
import CreateAccountBlock from './CreateAccountBlock'
import HeadlineAndBodyTextBlock from './HeadlineAndBodyTextBlock'
import WysiwygBlock from './WysiwygBlock'
import VideoBlock from './VideoBlock'
import IllustrationBlock from './IllustrationBlock'
import CodeExampleBlock from './CodeExampleBlock'
import TextWithIconCalloutsBlock from './TextWithIconCalloutsBlock'
import MultipleStepBlock from './MultipleStepBlock'
import HeroBlock from './HeroBlock'
import HowItWorkBlock from './HowItWorkBlock'
import QuoteBlock from './QuoteBlock'
import AccordionBlock from './AccordionBlock'
import ShowAllListenerBlock from './ShowAllListenerBlock'
import ShowAllPlatformBlock from './ShowAllPlatformBlock'
import ShowAllUseCaseBlock from './ShowAllUseCaseBlock'
import PackageBlock from './PackageBlock'
import MissionBlock from './MissionBlock'
import ListenersIntegrationBlock from './ListenersIntegrationBlock'
import SimpleUseCasesBlock from './SimpleUseCasesBlock'
import MasterclassBlock from './MasterclassBlock'
import LeadCollectBlock from './LeadCollectBlock'
import FormBlock from './form-block/FormBlock'
import PodcastBlock from './PodcastBlock'
import CTABlock from './CTABlock'
import HeroQuoteBlock from './HeroQuoteBlock'
import IntroCapabilitiesBlock from './IntroCapabilitiesBlock'
import MarketingPartnersBlock from './MarketingPartnersBlock'
import SupperPowerListenerBlock from './SupperPowerListenerBlock'
import TestimonialsBlock from './TestimonialsBlock'
import AdvantageBlock from './AdvantageBlock'
import HowToGetStartedBlock from './HowToGetStartedBlock'
import NewHeroBlock from './NewHeroBlock'
import HowItWorkV2Block from './HowItWorkV2Block'
import CardBlock from './CardBlock'
const Block = ({ block }) => {
     const {
          blockType,
          heroFields,
          headlineBodyFields,
          wysiwyfFields,
          videoFields,
          illustrationFields,
          codeExampleFields,
          textWithIconCalloutsFields,
          multipleStepSectionFields,
          howItWorkFields,
          quoteFields,
          faqsFields,
          allPlatformFields,
          allListenerFields,
          allUseCaseFields,
          packagesFields,
          missionFields,
          listenersIntegrationFields,
          simpleUseCasesFields,
          masterclassFields,
          leadCollectFields,
          formType,
          podcastFields,
          ctaFields,
          boxCard,
     } = block.cfBlock
     var content = ''
     switch (blockType) {
          case 'Create Account':
               content = <CreateAccountBlock />
               break
          case 'Headline & Body Text':
               content = (
                    <HeadlineAndBodyTextBlock
                         headlines={headlineBodyFields.headlines}
                         body={headlineBodyFields.body}
                         showButton={headlineBodyFields.showButton}
                         button={headlineBodyFields.button}
                         width={headlineBodyFields.width}
                         addSearch={headlineBodyFields.addSearch}
                         searchBox={headlineBodyFields.searchBox}
                         customClass={headlineBodyFields.customClass}
                    />
               )
               break
          case 'WYSIWYG':
               content = <WysiwygBlock values={wysiwyfFields} />
               break
          case 'Video':
               content = <VideoBlock {...videoFields} />
               break
          case 'Illustration':
               content = (
                    <IllustrationBlock
                         illustration={illustrationFields.illustrationEmbedCode}
                         illustrationMobile={illustrationFields.illustrationEmbedCodeMobile}
                    />
               )
               break
          case 'Code Examples':
               content = (
                    <CodeExampleBlock
                         values={codeExampleFields}
                         codeSections={codeExampleFields.codeSections}
                         displayType={codeExampleFields.displayType}
                         footnoteIcon={codeExampleFields.footnoteIcon}
                         footnoteText={codeExampleFields.footnoteText}
                    />
               )
               break
          case 'Text with Icon Callouts':
               content = <TextWithIconCalloutsBlock values={textWithIconCalloutsFields} />
               break
          case 'Multiple Step Sections':
               content = <MultipleStepBlock values={multipleStepSectionFields} />
               break
          case 'How It Work':
               content = (
                    <HowItWorkBlock
                         firstContent={howItWorkFields.firstContent}
                         actions={howItWorkFields.actions}
                         secondContent={howItWorkFields.secondContent}
                    />
               )
               break
          case 'Quote':
               content = (
                    <QuoteBlock quoteText={quoteFields.quoteText} avatar={quoteFields.avatar} name={quoteFields.name} detail={quoteFields.detail} />
               )
               break
          case 'FAQs':
               content = (
                    <AccordionBlock
                         faqs={faqsFields.faqs}
                         headline={faqsFields.headline}
                         showButton={faqsFields.showLoadMoreButton}
                         buttonText={faqsFields.buttonText}
                    />
               )
               break
          case 'All Use Case':
               content = <ShowAllUseCaseBlock headline={allUseCaseFields.headline} headlineType={allUseCaseFields.headlineType} />
               break
          case 'All Listener':
               content = (
                    <ShowAllListenerBlock
                         headlineSearch={allListenerFields.headline}
                         subHeadlineSearch={allListenerFields.subHeadline}
                         headlineRelated={allListenerFields.headlineFeatured}
                         showHeadlineAndBody={allListenerFields.showHeadlineAndBody}
                         headlineAndBody={allListenerFields.headlineAndBody}
                    />
               )
               break
          case 'All Platform':
               content = <ShowAllPlatformBlock headline={allPlatformFields.headline} />
               break
          case 'Packages':
               content = <PackageBlock packages={packagesFields.packages} />
               break
          case 'Mission':
               content = <MissionBlock headline={missionFields.headline} ourGoals={missionFields.ourGoals} ourMissions={missionFields.ourMissions} />
               break
          case 'Listeners Integration':
               content = (
                    <ListenersIntegrationBlock
                         buttonBrowse={listenersIntegrationFields.buttonBrowse}
                         listeners={listenersIntegrationFields.listeners}
                    />
               )
               break
          case 'Simple Use Cases':
               content = (
                    <SimpleUseCasesBlock
                         headline={simpleUseCasesFields.headline}
                         headlineType={simpleUseCasesFields.headlineType}
                         usecases={simpleUseCasesFields.simpleUseCasesPost}
                    />
               )
               break
          case 'Masterclass':
               content = <MasterclassBlock values={masterclassFields} />
               break
          case 'Lead Collect':
               content = <LeadCollectBlock values={leadCollectFields} />
               break
          case 'Form':
               content = <FormBlock formType={formType} />
               break
          case 'Podcast':
               content = <PodcastBlock {...podcastFields} />
               break
          case 'CTA':
               content = <CTABlock {...ctaFields} />
               break
          case 'New Hero Section':
               content = (
                    <NewHeroBlock
                         positioning={`Center`}
                         h1={`Data <strong>Freedom</strong> for Marketers`}
                         subText={`<p>The <strong>fastest</strong> and <strong>most reliable</strong> way to get all the data you need to <strong>prove (and boost) your marketing results</strong> - no programming required!</p>`}
                         width={`Large`}
                    />
               )
               break
          case 'Hero Quote':
               content = (
                    <HeroQuoteBlock quote={heroFields?.quote} meta={heroFields?.meta} avatar={heroFields?.avatar} textSize={heroFields?.textSize} />
               )
               break
          case 'Intro Capabilities':
               content = <IntroCapabilitiesBlock />
               break
          case 'Marketing Partners':
               content = <MarketingPartnersBlock />
               break
          case 'Testimonials':
               content = <TestimonialsBlock />
               break
          case 'Supper Power Listener':
               content = <SupperPowerListenerBlock />
               break
          case 'Advantage':
               content = <AdvantageBlock />
               break
          case 'How To Get Started':
               content = <HowToGetStartedBlock />
               break
          case 'How It Work V2':
               content = <HowItWorkV2Block />
               break
          case 'Box Card':
               content = <CardBlock boxCard={boxCard} />
               break

          default:
               content = (
                    <>
                         <HeroBlock positioning={heroFields.positioning} h1={heroFields.h1} subText={heroFields.subText} width={heroFields.width} />
                    </>
               )
               break
     }

	return <>{content}</>
}

export default Block
