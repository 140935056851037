import React, { Fragment } from 'react'
import HeadlineTypeBlock from '../Blocks/HeadlineTypeBlock'

const PodcastBlock = values => {
     const { headlineType, headline, podcastEmbedCode, subHeadline, description, podcastLinks } = values

     return (
          <div className="podcast-block">
               {headline && (
                    <div className="headline">
                         <HeadlineTypeBlock headline={headline} headlineType={headlineType} />
                    </div>
               )}
               {podcastEmbedCode && (
                    <div className="embed-code-iframe">
                         <div dangerouslySetInnerHTML={{ __html: podcastEmbedCode }}></div>
                    </div>
               )}
               {subHeadline && <p className="sub-headline">{subHeadline}</p>}
               {description && <p className="description">{description}</p>}
               {Array.isArray(podcastLinks) && podcastLinks.length > 0 && (
                    <div className="podcast-links">
                         {podcastLinks?.map?.((link, index) => {
                              let optionalAttrs = {}

                              if (link.openNewTab) {
                                   optionalAttrs.target = '_blank'
                              }

                              return (
                                   <Fragment key={index}>
                                        {(link.text || link.iconSvg) && (
                                             <a key={link.url} href={link.url} rel="noreferrer" {...optionalAttrs}>
                                                  {link.text && <span>{link.text}</span>}
                                                  {link.iconSvg && <span dangerouslySetInnerHTML={{ __html: link.iconSvg }}></span>}
                                             </a>
                                        )}
                                   </Fragment>
                              )
                         })}
                    </div>
               )}
          </div>
     )
}
export default PodcastBlock
