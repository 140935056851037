
import React, { useState, useEffect, useCallback, Fragment } from 'react'
import axios from 'axios'
import { REGEX_EMAIL } from '../../constants'
import Spinner from 'react-bootstrap/Spinner'
import Google from './Google'
import Cookies from 'js-cookie'
import { setIsLogin } from '../../actions/common'
import { useDispatch, useSelector } from 'react-redux'
import { useScript } from '../../hooks/useScript'
import { addSlashInternalLink, formatDateCount } from '../../helper'

const MasterclassBlock = ({ values }) => {
    const isLogin = useSelector(state => state.theme.isLogin);
    const [openPopup, setOpenPopup] = useState(false)

    const {
        headlineH1,
        headlineH2,
        subHeadline,
        firstButton,
        noteUnderFirstButton,
        processMasterclass,
        noteProcess,
        boxReceive,
        secondButton,
        thirdButton,
        videoEmbedCode
    } = values

    const [payOnly, setPayOnly] = useState(boxReceive.payOnlyTimer)
    const [saveCost, setSaveCost] = useState(boxReceive.saveCostTimer)
    const [countDownEnd, setCountDownEnd] = useState(false)
    const [ipAddress, setIpAddress] = useState(null);

    const countDownDate = new Date(boxReceive.dateSave).getTime();

    useEffect(() => {
        if (typeof document !== "undefined") {
            const countDown = setInterval(function () {
                var now = new Date().getTime();
                var distance = countDownDate - now;

                if (distance < 0) {
                    clearInterval(countDown);
                    setPayOnly(boxReceive.payOnly)
                    setSaveCost(boxReceive.saveCost)
                    setCountDownEnd(true)
                } else {
                    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + (days * 24));
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    if (hours < 10) {
                        hours = '0' + hours
                    }

                    if (minutes < 10) {
                        minutes = '0' + minutes
                    }

                    if (seconds < 10) {
                        seconds = '0' + seconds
                    }

                    document.getElementById("hrs").innerHTML = hours;
                    document.getElementById("min").innerHTML = minutes;
                    document.getElementById("sec").innerHTML = seconds;
                }
            }, 1000);
        }
    }, [boxReceive.payOnly, boxReceive.saveCost, countDownDate])

    useEffect(() => {
        const getCloudflareJSON = async () => {
             try {
                  let data = await fetch('https://www.cloudflare.com/cdn-cgi/trace').then((res) => res.text());
                  let arr = data
                       .trim()
                       .split('\n')
                       .map((e) => e.split('='));
                  return Object.fromEntries(arr);
             } catch (error) {
                  console.error('Error fetching data:', error);
                  return null;
             }
        };

        const fetchData = async () => {
             const res = await getCloudflareJSON();
             setIpAddress(res.ip);
        };

        fetchData();
   }, []);

    const handleClickRegisterMasterclass = () => {
        if (isLogin) {
            window.open(`${process.env.GATSBY_APP_PATH}/my-account/masterclass?registerMasterClass=true`)
        } else {
            setOpenPopup(true)
        }
    }

    const [email, setEmail] = useState()
    const [errorEmail, setErrorEmail] = useState()
    const [password, setPassword] = useState()
    const [errorPassword, setErrorPassword] = useState()
    const [errorForm, setErrorForm] = useState()
    const [firstName, setFirstName] = useState()
    const [errorFirstName, setErrorFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [errorLastName, setErrorLastName] = useState()
    const [loading, setLoading] = useState(false)
    const [successRegister, setSuccessRegister] = useState()
    const [openLoginMasterClass, setOpenLoginMasterClass] = useState(false)
    const dispatch = useDispatch();

    const closePopupRegister = () => {
        setFirstName()
        setLastName()
        setEmail()
        setPassword()
        setSuccessRegister()
        setErrorForm()
        setErrorEmail()
        setErrorPassword()
        setErrorFirstName()
        setErrorLastName()
        setOpenPopup(false)
    }

    const closePopupLogin = () => {
        setEmail()
        setPassword()
        setErrorForm()
        setErrorEmail()
        setErrorPassword()
        setOpenLoginMasterClass(false)
    }

    const handleSubmitFormRegister = (event) => {
        event.preventDefault()
        let firstNameMess = ''
        let lastNameMess = ''
        let emailMess = ''
        let passwordMess = ''

        if (!email) {
            emailMess = 'Email is required'
        } else if (!REGEX_EMAIL.test(email)) {
            emailMess = 'Not a valid email'
        }

        if (!password) {
            passwordMess = 'Password is required'
        } else if (password.length < 8) {
            passwordMess = 'Password must be at least  8 characters'
        }

        if (!firstName) {
            firstNameMess = 'First name is required'
        }

        if (!lastName) {
            lastNameMess = 'Last name is required'
        }

        setErrorEmail(emailMess)
        setErrorPassword(passwordMess)
        setErrorFirstName(firstNameMess)
        setErrorLastName(lastNameMess)

        if (!emailMess && !passwordMess && !firstNameMess && !lastNameMess) {
            setLoading(true)
            axios.post(`${process.env.GATSBY_CALL_API}/listener-layer-signup`, {
                email,
                password,
                firstName,
                lastName,
            }).then((response) => {
                setLoading(false)
                if (response.status === 200) {
                    dispatch(setIsLogin(true))
                    setErrorForm()
                    Cookies.set('token', response.data.token, { expires: 1, domain: 'listenlayer.com' })
                    if (response.data.user) {
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                    }
                    window.open(`${process.env.GATSBY_APP_PATH}/my-account/masterclass?registerMasterClass=true`)
                    window.location.reload()
                }
            }).catch((error) => {
                setLoading(false)
                setSuccessRegister()
                const message = error.response.data.message || error.response.data.error;
                setErrorForm(message || error.response.statusText)
            })
        }
    }

    const handleSubmitForm = (event) => {
        event.preventDefault()
        let emailMess = ''
        let passwordMess = ''

        if (!email) {
            emailMess = 'Email is required'
        } else if (!REGEX_EMAIL.test(email)) {
            emailMess = 'Not a valid email'
        }

        if (!password) {
            passwordMess = 'Password is required'
        } else if (password.length < 8) {
            passwordMess = 'Password must be at least  8 characters'
        }

        setErrorEmail(emailMess)
        setErrorPassword(passwordMess)

        if (!emailMess && !passwordMess) {
            setLoading(true)
            axios.post(`${process.env.GATSBY_CALL_API}/signin/`, {
                email: email,
                password: password,
                ipAddress: ipAddress
            }).then((response) => {
                dispatch(setIsLogin(true))
                setLoading(false)
                setErrorForm()
                Cookies.set('token', response.data.token, { expires: 1, domain: '.listenlayer.com', })
                window.open(`${process.env.GATSBY_APP_PATH}/my-account/masterclass?registerMasterClass=true`)
                window.location.reload()
            }).catch((error) => {
                setLoading(false)
                const message = error.response.data.message || error.response.data.error;
                setErrorForm(message || error.response.statusText)
            })
        }
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
        setErrorEmail()
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
        setErrorPassword()
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
        setErrorFirstName()
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
        setErrorLastName()
    }

    const [googleOneTap, setGoogleOneTap] = useState(false);
    const scriptFlag = '__googleOneTapScript__';

    const script = useScript('https://accounts.google.com/gsi/client');

    const onOneTapSignedIn = useCallback((response) => {
        axios.post(`${process.env.GATSBY_CALL_API}/google-one-tap-login/`, {
            oneTapToken: response.credential,
            registerMasterClass: true,
            ipAddress: ipAddress
        }).then((response) => {
            dispatch(setIsLogin(true))
            setLoading(false)
            Cookies.set('token', response.data.token, { expires: 1, domain: '.listenlayer.com', })
            window.open(`${process.env.GATSBY_APP_PATH}/my-account/masterclass?registerMasterClass=true`)
            window.location.reload()
        }).catch((error) => {
            setLoading(false)
        })
    }, [dispatch])


    useEffect(() => {
        try {
            if (typeof window !== 'undefined' && script === 'ready') {
                window.google.accounts.id.initialize({
                    client_id: process.env.GATSBY_APP_GOOGLE_CLIENT_ID,
                    prompt_parent_id: 'google-one-tap-register',
                    cancel_on_tap_outside: false,
                    callback: onOneTapSignedIn,
                    context: 'signup'
                });
                window[scriptFlag] = true;
                setGoogleOneTap(true);
            }
            if (window[scriptFlag] && script === 'ready' && openPopup) {
                window.google.accounts.id.prompt(notification => {
                    if (notification.isNotDisplayed() || notification.isSkippedMoment() || notification.getDismissedReason()) {
                        setGoogleOneTap(false);
                    }
                });
            }
            return () => {
                if (typeof window !== 'undefined' && script === 'ready') {
                    window.google.accounts.id.cancel();
                }
            }
        } catch (error) {

        }
    }, [script, openPopup, onOneTapSignedIn])

    useEffect(() => {
        try {
            if (typeof window !== 'undefined' && script === 'ready') {
                window.google.accounts.id.initialize({
                    client_id: process.env.GATSBY_APP_GOOGLE_CLIENT_ID,
                    prompt_parent_id: 'google-one-tap',
                    cancel_on_tap_outside: false,
                    callback: onOneTapSignedIn
                });
                window[scriptFlag] = true;
                setGoogleOneTap(true);
            }
            if (window[scriptFlag] && script === 'ready' && openLoginMasterClass) {
                window.google.accounts.id.prompt(notification => {
                    if (notification.isNotDisplayed() || notification.isSkippedMoment() || notification.getDismissedReason()) {
                        setGoogleOneTap(false);
                    }
                });
            }
            return () => {
                if (typeof window !== 'undefined' && script === 'ready') {
                    window.google.accounts.id.cancel();
                }
            }
        } catch (error) {

        }
    }, [script, openLoginMasterClass, onOneTapSignedIn])

    return (
        <div className="masterclass-section">
            {
                headlineH1 && (
                    <div className="headline hero-wrapper">
                        <h1>{headlineH1}</h1>
                    </div>
                )
            }
            {
                subHeadline && (
                    <div className="sub-headline" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(subHeadline) }}></div>
                )
            }
            {
                firstButton && firstButton.firstText && (
                    <div className="btn-wrapper">
                        <button onClick={() => handleClickRegisterMasterclass()} className="btn-two-line">
                            <span>{firstButton.firstText}</span>
                            {
                                firstButton.secondText && (
                                    <span>{firstButton.secondText}</span>
                                )
                            }

                        </button>
                    </div>
                )
            }
            {
                noteUnderFirstButton && (
                    <div className="note-under-btn">
                        <p dangerouslySetInnerHTML={{ __html: noteUnderFirstButton }}></p>
                    </div>
                )
            }
            {
                videoEmbedCode && (
                    <div class="video-embed-code  ">
                        <div class="video-embed-code-iframe">
                            <div dangerouslySetInnerHTML={{ __html: videoEmbedCode }}></div>
                        </div>
                    </div>
                )
            }

            {
                secondButton && secondButton.firstText && (
                    <div className="btn-wrapper">
                        <button onClick={() => handleClickRegisterMasterclass()} className="btn-two-line">
                            <span>{firstButton.firstText}</span>
                            {
                                secondButton.secondText && (
                                    <span>{secondButton.secondText}</span>
                                )
                            }
                        </button>
                    </div>
                )
            }

            <div className="process-masterclass">
                {
                    Array.isArray(processMasterclass) && processMasterclass.length > 0 && (
                        <div className="wrapper-process">
                            {
                                processMasterclass.map((process, index) => {
                                    let imageArrow = ''
                                    let imageArrowLong = ''
                                    if (index === 0) {
                                        imageArrow = `/images/line-short.svg`
                                    } else if (index === 2) {
                                        imageArrow = `/images/line-medium.svg`
                                    } else if (index === 1) {
                                        imageArrowLong = `/images/line-long.svg`
                                    }
                                    return (
                                        <Fragment key={index}>
                                            <div className="item">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: process.iconSvg }}></div>
                                                <div className="content">
                                                    {process.title && (
                                                        <p>{process.title}</p>
                                                    )}
                                                </div>
                                                {imageArrow && (
                                                    <img className="arrow" src={imageArrow} alt="Arrow Line " />
                                                )}
                                            </div>
                                            {imageArrowLong && (
                                                <img className="arrow long" src={imageArrowLong} alt="Arrow Line " />
                                            )}
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    )
                }
                {
                    noteProcess && (
                        <div className="text-under-process">
                            <p>{noteProcess}</p>
                        </div>
                    )
                }
            </div>
            <div className="register-masterclass">
                {
                    headlineH2 && (
                        <div className="headline">
                            <h2>{headlineH2}</h2>
                        </div>
                    )
                }
                <div className={`box-register ${!countDownEnd ? '' : 'no-count'}`}>
                    {
                        boxReceive.boxTitle && (
                            <div className="headline">
                                <p>{boxReceive.boxTitle}</p>
                            </div>
                        )
                    }
                    <ul>
                        {
                            boxReceive.listReceive.map((receive, index) => {
                                return (
                                    <li key={index}>
                                        <span>{receive.name}</span>
                                        <span>${receive.cost.toLocaleString('en-us')}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {
                        boxReceive.totalValue && (
                            <div className="total">
                                <p>Total Value  ${boxReceive.totalValue.toLocaleString('en-us')}</p>
                            </div>
                        )
                    }
                    {
                        payOnly && (
                            <div className="pay-only">
                                <p>You pay only ${payOnly.toLocaleString('en-us')}</p>
                            </div>
                        )
                    }
                    {
                        saveCost && (
                            <div className="save-cost">
                                <p>and <span>save ${saveCost.toLocaleString('en-us')}</span> when you sign up {!countDownEnd ? formatDateCount(boxReceive.dateSave) : ''}</p>
                            </div>
                        )
                    }
                    {
                        !countDownEnd && (
                            <div className="count-down">
                                <div className="time hrs">
                                    <p id="hrs">00</p>
                                    <span>hrs</span>
                                </div>
                                <div className="time min">
                                    <p id="min">00</p>
                                    <span>min</span>
                                </div>
                                <div className="time sec">
                                    <p id="sec">00</p>
                                    <span>sec</span>
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    thirdButton && thirdButton.firstText && (
                        <div className="btn-wrapper">
                            <button onClick={() => handleClickRegisterMasterclass()} className="btn-two-line">
                                <span>{firstButton.firstText}</span>
                                {
                                    thirdButton.secondText && (
                                        <span>{thirdButton.secondText}</span>
                                    )
                                }
                            </button>
                        </div>
                    )
                }
            </div>
            {
                openPopup && (
                    <div className="login-form-modal">
                        <div className="login-section-main">
                            <div className="login-section-card-wrapper">
                                <button className="login-icon-close" onClick={() => closePopupRegister()}><img width="20" height="20" alt="Icon Close" src="/images/icon-close.svg" /></button>
                                <div className="login-section-card-scroll">
                                    <div className={`login-section-card member-login${googleOneTap ? ' has-google-one-tap' : ''}`}>
                                        <div className="login-section-card-title"><h2>Purchase Your Masterclass Seat</h2></div>
                                        <div className="login-section-card-description">
                                            <p>Create your ListenLayer account to purchase the Masterclass.</p>
                                        </div>
                                        <form className="website-create-account" onSubmit={handleSubmitFormRegister}>
                                            <div className="input-fullname">
                                                <div className="field-wrapper">
                                                    <input type="text" aria-required="true" name="firstName-mc" placeholder="First name" onChange={handleFirstNameChange} />
                                                    {errorFirstName && <div className="login-invalid-feedback">{errorFirstName}</div>}
                                                </div>
                                                <div className="field-wrapper">
                                                    <input type="text" aria-required="true" name="lastName-mc" placeholder="Last name" onChange={handleLastNameChange} />
                                                    {errorLastName && <div className="login-invalid-feedback">{errorLastName}</div>}
                                                </div>
                                            </div>
                                            <div className="field-wrapper">
                                                <input type="text" aria-required="true" name="email-mc" placeholder="Email address" onChange={handleEmailChange} />
                                                {errorEmail && <div className="login-invalid-feedback">{errorEmail}</div>}
                                            </div>
                                            <div className="field-wrapper">
                                                <input type="password" aria-required="true" name="password-mc" placeholder="Password" onChange={handlePasswordChange} />
                                                {errorPassword && <div className="login-invalid-feedback">{errorPassword}</div>}
                                            </div>
                                            <div className="login-section-card-button register-section-card-button">
                                                <button type="submit" className="btn">
                                                    {
                                                        loading ? (
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        ) : "Get Started"
                                                    }
                                                </button>
                                                {errorForm && <div className="error form">{errorForm}</div>}
                                                {successRegister && <div className="success form">{successRegister}</div>}
                                                <button
                                                    onClick={() => {
                                                        setOpenLoginMasterClass(true)
                                                        closePopupRegister()
                                                    }}
                                                    className="under-submit"
                                                >
                                                    Login to existing account
                                                </button>
                                            </div>
                                        </form>
                                        <div className="login-card-with-google">
                                            <div id="google-one-tap-register"></div>
                                            {!googleOneTap && <Google className="btn-google-outline" labelButton="Sign Up With Google" isIconColor={true} registerMasterClass={true} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div aria-label='overlay form' tabIndex='-1' role="button" onClick={() => {
                            closePopupRegister()
                        }} className="form-overlay"></div>
                    </div>
                )
            }
            {
                openLoginMasterClass && (
                    <div className="login-form-modal">
                        <div className="login-section-main">
                            <div className="login-section-card-wrapper">
                                <button className="login-icon-close" onClick={() => closePopupLogin()}><img width="20" height="20" alt="Icon Close" src="/images/icon-close.svg" /></button>
                                <div className="login-section-card-scroll">
                                    <div className={`login-section-card member-login${googleOneTap ? ' has-google-one-tap' : ''}`}>
                                        <div className="login-section-card-title"><h2>Member Login</h2></div>
                                        <div className="login-section-card-description">
                                            <p>Log in to your ListenLayer<sup>TM</sup> account</p>
                                        </div>
                                        <form className="website-login" onSubmit={handleSubmitForm}>
                                            <div className="field-wrapper">
                                                <input type="text" aria-required="true" name="email-login-mc" placeholder="Enter your email" onChange={handleEmailChange} />
                                                {errorEmail && <div className="login-invalid-feedback">{errorEmail}</div>}
                                            </div>
                                            <div className="field-wrapper">
                                                <input type="password" aria-required="true" name="password-login-mc" placeholder="Enter your password" onChange={handlePasswordChange} />
                                                {errorPassword && <div className="login-invalid-feedback">{errorPassword}</div>}
                                            </div>
                                            <div className="login-section-card-button">
                                                <button type="submit" className="btn">
                                                    {
                                                        loading ? (
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        ) : "Login"
                                                    }
                                                </button>
                                                {errorForm && <div className="error form">{errorForm}</div>}
                                                <a href={`${process.env.GATSBY_APP_PATH}/auth/password/forgot`} target="_blank" rel="noreferrer">Reset password?</a>
                                            </div>
                                        </form>
                                        <div className="login-card-with-google">
                                            <div id="google-one-tap"></div>
                                            {!googleOneTap && <Google className="btn-google-outline" labelButton="Login with Google" isIconColor={true} registerMasterClass={true} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div aria-label='overlay form' tabIndex='-1' role="button" onClick={() => {
                            closePopupLogin()
                        }} className="form-overlay"></div>
                    </div>
                )
            }
        </div>
    )
}

export default MasterclassBlock
