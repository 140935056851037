import React from 'react'

export const HeroQuoteBlock = ({ quote, meta, avatar, textSize = 'Large' }) => {
    const textSizeClass = textSize === 'Medium' ? 'size-medium' : (
        textSize === 'Large' ? 'size-large' : ''
    )
    return (
        <>
            {quote && (
                <div className={`hero-quote-wrapper`}>
                    <div className={`hero-quote-content ${textSizeClass}`}>
                        <div dangerouslySetInnerHTML={{ __html: quote }} />
                        {meta && <div className='hero-quote-meta'>
                            {meta.name && <span className='name'>{meta.name},</span>}
                            {meta.position && <span className='position'>{meta.position}</span>}
                        </div>}
                    </div>
                    {avatar && <div className='hero-quote-avatar'>
                        <img src={avatar.src} alt={avatar.alt} />
                    </div>}
                </div>
            )}
        </>
    )
}

export default HeroQuoteBlock
