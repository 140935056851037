import React, { Fragment } from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'

export const TestimonialsBlock = () => {
     const blocks = [
          {
               content: `<p>“I’ve been using ListenLayer since 2021 and can’t imagine a better way to help track significant user interactions on a website.”</p>`,
               meta: { name: `Fred Pike`, position: `NWS Digital` },
               image: { src: '/images/northwoods-logo.png', alt: 'Northwoods' },
          },
          // {
          //      content: `<p>“ListenLayer is an absolute game-changer! Its unmatched speed and reliability provide all the data essential to optimize our marketing ROI effortlessly without the hassle of programming.”</p>`,
          //      meta: { name: `Fred Pike`, position: `NWS Digital` },
          //      image: { src: '/images/trimble-logo.svg', alt: 'trimble' },
          // },
          // {
          //      content: `<p>“I’ve been using ListenLayer since 2021 and can’t imagine a better way to help track significant user interactions on a website.”</p>`,
          //      meta: { name: `Fred Pike`, position: `NWS Digital` },
          //      image: { src: '/images/blackline-logo.svg', alt: 'blackline' },
          // },
          // {
          //      content: `<p>“ListenLayer is an absolute game-changer! Its unmatched speed and reliability provide all the data essential to optimize our marketing ROI effortlessly without the hassle of programming.”</p>`,
          //      meta: { name: `Daniel Demopal`, position: `Director of Marketing, Stewart Title` },
          //      image: { src: '/images/stewart-logo.png', alt: 'Stewart' },
          // },
     ]
     const settings = {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
     }
     return (
          <>
               {blocks && (
                    <div className="testimonial-wrapper">
                         {blocks && (
                              <div className="testimonial-list">
                                   <Slider {...settings}>
                                        {blocks.map((block, index) => {
                                             const { content, meta, image } = block
                                             if (content && image) {
                                                  return (
                                                       <div key={index} className="testimonial-block">
                                                            <div className="testimonial-content" dangerouslySetInnerHTML={{ __html: content }} />
                                                            {image && (
                                                                 <div className="testimonial-image">
                                                                      <img src={image.src} alt={image.alt} />
                                                                 </div>
                                                            )}
                                                            {meta && (
                                                                 <div className="testimonial-meta">
                                                                      {meta.name && <span className="name">{meta.name},</span>}
                                                                      {meta.position && <span className="position">{meta.position}</span>}
                                                                 </div>
                                                            )}
                                                       </div>
                                                  )
                                             }
                                             return <Fragment key={index}></Fragment>
                                        })}
                                   </Slider>
                              </div>
                         )}
                    </div>
               )}
          </>
     )
}

export default TestimonialsBlock
