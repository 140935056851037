import React from 'react'
import HeadlineTypeBlock from '../Blocks/HeadlineTypeBlock'
import UseCaseBlock from '../Blocks/UseCaseBlock'
import { getSlug, replaceStr } from '../../helper'
import { USECASE_SHORTCODE } from '../../constants'

const SimpleUseCasesBlock = ({ headline, headlineType, usecases }) => {
    const usecaseSlug = getSlug(usecases[0].uri)
    return (
        <div className="simple-usecase use-cases-wrapper">
            <div className="headline">
                <HeadlineTypeBlock headline={headline} headlineType={headlineType} />
            </div>
            <div className="list-usecase-wrapper">
                <div className="row">
                    {
                        usecases && usecases.map((usecase, index) => {
                            const usecaseName = [usecase.cfUseCase.mainElements.usecaseNamePlural, usecase.cfUseCase.mainElements.usecaseNameSingular]
                            return (
                                <div key={index} className="col-md-6 col-lg-4">
                                    <UseCaseBlock
                                        imageExcerpt={usecase.cfUseCase.mainElements.excerptImage}
                                        title={replaceStr(
                                            USECASE_SHORTCODE,
                                            usecaseName,
                                            usecase.cfUseCase.mainElements.excerptTitle || usecase.cfUseCase.mainElements.h1
                                        )}
                                        excerpt={replaceStr(USECASE_SHORTCODE, usecaseName, usecase.cfUseCase.mainElements.useCaseExcerpt)}
                                        usecaseSlug={`/${usecaseSlug}/${usecase.cfUseCase.mainElements.slug}/`}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default SimpleUseCasesBlock