import React from 'react'

const TextWithIconCalloutsBlock = ({ values }) => {
    return (
        <div className="callout-wrapper">
            <div className="headline" dangerouslySetInnerHTML={{ __html: values.headline }}></div>
            <div className="callouts">
                {
                    values.iconsAndCallouts && values.iconsAndCallouts.map((callout, index) => {
                        return (
                            <div key={index} className="callout">
                                {
                                    callout.imageType === "Image" && callout.image ? (
                                        <div className="icon"><img src={callout.image.mediaItemUrl} alt={callout.image.title} /></div>
                                    ) : (
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: callout.svg }}></div>
                                    )
                                }
                                <div className="text"><p>{callout.calloutText}</p></div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TextWithIconCalloutsBlock
