import React, { useState } from 'react'
import axios from 'axios'
import { REGEX_EMAIL } from '../../constants'
import Spinner from 'react-bootstrap/Spinner'

const LeadCollectBlock = ({ values }) => {
    const [successRegister, setSuccessRegister] = useState();
    const [errorForm, setErrorForm] = useState();
    const [loading, setLoading] = useState(false);
    const [errorEmail, setErrorEmail] = useState();
    const [errorFirstName, setErrorFirstName] = useState();
    const [errorLastName, setErrorLastName] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [openLoginPopup, setOpenLoginPopup] = useState(false);
    const [displayThankYouMessage, setDisplayThankYouMessage] = useState(false);
    const {
        headline,
        subHeadline,
        buttonFirstText,
        buttonSecondText,
        buttonTextPopup,
        urlRedirect,
        displayThankYouMessageInstead,
        thankYouMessage,
        sendgridCustomField
    } = values;

    const closePopup = () => {
        setFirstName();
        setLastName();
        setEmail();
        setSuccessRegister();
        setErrorForm();
        setOpenLoginPopup(false);
        setErrorEmail();
        setErrorFirstName();
        setErrorLastName();
        setDisplayThankYouMessage(false);
    }

    const onKeyNameEscape = (event) => {
        if (event.keyCode === 27) {
            setOpenLoginPopup(false);
        }
    }

    const collectMailToSendgrid = (customFieldId) => {
        let data = {};

        if (customFieldId === false) {
            data = {
                contacts: [
                    {
                        first_name: firstName,
                        last_name: lastName,
                        email: email,
                        custom_fields: { // custom fields just send id
                            'w5_T': '1' // id custom field lead
                        }
                    }
                ]
            }
        } else {
            data = {
                contacts: [
                    {
                        first_name: firstName,
                        last_name: lastName,
                        email: email,
                        custom_fields: { // custom fields just send id
                            [customFieldId]: "1", // id custom field lead page
                            'w5_T': '1' // id custom field lead
                        }
                    }
                ]
            }
        }

        const options = {
            method: 'PUT',
            // headers: { 'Authorization': `Bearer ${process.env.GATSBY_SENDGRID_TOKEN}`, 'Content-Type': 'application/json' },
            // url: `${process.env.GATSBY_SENDGRID_API}marketing/contacts`,
            url: `${process.env.GATSBY_CALL_API}/marketing/contacts`,
            data: data
        };

        axios(options)
            .then((response) => {
                setLoading(false)

                if (response.status === 200) {
                    
                    if (displayThankYouMessageInstead) {
                        setDisplayThankYouMessage(true);
                    } else {
                        setSuccessRegister('Thank you for your subscribe!')
                        setTimeout(() => {
                            if (urlRedirect) {
                                window.location.assign(urlRedirect);
                            }
                        }, 3000);
                    }
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error);
            })
    }

    const getCustomFieldID = async () => {
        const options = {
            method: 'GET',
            // headers: { 'Authorization': `Bearer ${process.env.GATSBY_SENDGRID_TOKEN}`, 'Content-Type': 'application/json' },
            // url: `${process.env.GATSBY_SENDGRID_API}marketing/field_definitions`,
            url: `${process.env.GATSBY_CALL_API}/marketing/field_definitions`,
        };
        let response = await axios(options);

        if (response.status === 200) {
            const listCustomField = response.data.custom_fields;
            let found = listCustomField.find(customField => customField.name === sendgridCustomField);
            if (found) {
                collectMailToSendgrid(found.id);
            } else {
                collectMailToSendgrid(false)
            }
        } else {
            setLoading(false)
            console.log(response);
        }
    }

    const handleSubmitFormRegister = (event) => {
        event.preventDefault()
        let firstNameMess = ''
        let lastNameMess = ''
        let emailMess = ''

        if (!email) {
            emailMess = 'Email is required'
        } else if (!REGEX_EMAIL.test(email)) {
            emailMess = 'Not a valid email'
        }

        if (!firstName) {
            firstNameMess = 'First name is required'
        }

        if (!lastName) {
            lastNameMess = 'Last name is required'
        }

        setErrorEmail(emailMess)
        setErrorFirstName(firstNameMess)
        setErrorLastName(lastNameMess)

        if (!emailMess && !firstNameMess && !lastNameMess) {
            setLoading(true)
            getCustomFieldID()
        }
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
        setErrorFirstName()
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
        setErrorEmail()
    }


    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
        setErrorLastName()
    }

    return (
        <div className="lead-collection">
            <div className="btn-wrapper">
                <button className="btn-two-line" onClick={() => setOpenLoginPopup(true)}>
                    <span>{buttonFirstText ? buttonFirstText : "Reserve My Invitation"}</span>
                    <span>{buttonSecondText ? buttonSecondText : "Sign up for a personalized invitation to the upcoming offer!"}</span>
                </button>
            </div>
            {
                openLoginPopup && (
                    <div className="login-form-modal">
                        <div className="login-section-main">
                            <div className="login-section-card-wrapper">
                                <button className="login-icon-close" onClick={() => closePopup()}><img width="20" height="20" alt="Icon Close" src="/images/icon-close.svg" /></button>
                                <div className="login-section-card-scroll">
                                    <div className={`login-section-card member-login`}>
                                        {
                                            displayThankYouMessage ? (
                                                <>
                                                    {thankYouMessage?.headline && <div className="login-section-card-title"><h2>{thankYouMessage.headline}</h2></div>}
                                                    {
                                                        thankYouMessage?.subHeadline && (
                                                            <div className="login-section-card-description">
                                                                <p>{thankYouMessage.subHeadline}</p>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <div className="login-section-card-title"><h2>{headline ? headline : 'Register'}</h2></div>
                                                    {
                                                        subHeadline && (
                                                            <div className="login-section-card-description">
                                                                <p>{subHeadline}</p>
                                                            </div>
                                                        )
                                                    }
                                                    <form onSubmit={handleSubmitFormRegister}>
                                                        <div className="input-fullname">
                                                            <div className="field-wrapper">
                                                                <input type="text" aria-required="true" name="firstName-lead" placeholder="First name" onChange={handleFirstNameChange} />
                                                                {errorFirstName && <div className="login-invalid-feedback">{errorFirstName}</div>}
                                                            </div>
                                                            <div className="field-wrapper">
                                                                <input type="text" aria-required="true" name="lastName-lead" placeholder="Last name" onChange={handleLastNameChange} />
                                                                {errorLastName && <div className="login-invalid-feedback">{errorLastName}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="field-wrapper">
                                                            <input type="text" aria-required="true" name="email-lead" placeholder="Email address" onChange={handleEmailChange} />
                                                            {errorEmail && <div className="login-invalid-feedback">{errorEmail}</div>}
                                                        </div>
                                                        <div className="login-section-card-button register-section-card-button">
                                                            <button type="submit" className="btn">
                                                                {
                                                                    loading ? (
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                    ) : (buttonTextPopup ? buttonTextPopup : "Register Now")
                                                                }
                                                            </button>
                                                            {errorForm && <div className="error form">{errorForm}</div>}
                                                            {successRegister && <div className="success form">{successRegister}</div>}
                                                        </div>
                                                    </form>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div aria-label='overlay form' tabIndex='-1' role="button" onClick={() => {
                            closePopup()
                        }} onKeyDown={onKeyNameEscape} className="form-overlay"></div>
                    </div>
                )
            }
        </div>
    )
}

export default LeadCollectBlock
