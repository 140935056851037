import React from 'react'
import { navigate } from '@reach/router'

const NewCreateAccountBlock = () => {
     const onClickGetStarted = () => {
          navigate(`${process.env.GATSBY_APP_PATH}/register/?navigate=true`)
          // window.location.replace(`${process.env.GATSBY_APP_PATH}/register`)
     }

     return (
          <div className="login-section-main">
               <div className="login-section-card-wrapper">
                    {/* <button className="login-icon-close" onClick={() => closePopup()}><img width="20" height="20" alt="Icon Close" src="/images/icon-close.svg" /></button> */}
                    <div className="login-section-card-scroll">
                         <div className={`login-section-card member-login`}>
                              <div className="login-section-card-title">
                                   <h2>Ready to get started?</h2>
                              </div>
                              <div className="login-section-card-description">
                                   <p>See how easy (and powerful) ListenLayer is by opening an account today or requesting a demo.</p>
                              </div>
                              <div className="login-section-card-button register-section-card-button">
                                   <button onClick={() => onClickGetStarted()} type="submit" className="btn">
                                        Get Started
                                   </button>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default NewCreateAccountBlock
