import React, { useState } from 'react'

const DataRequests = () => {
     return (
          <div className="data-requests">
               <p>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdz8iqt0wa85sQa8dL6JIwpaLmlw9LrW_n_auOWVFuhhFvbfA/viewform" target="_blank">
                         Data Deletion Request Form
                    </a>
               </p>
               <p>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdubkyBGpTAwnmFc4zjICPhuY96FK8d9ZkR-NxCaqt92sm9Vw/viewform" target="_blank">
                         Exercise Your Other Data Rights
                    </a>
               </p>
          </div>
     )
}

export default DataRequests
