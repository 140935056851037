import React, { Fragment } from 'react'
import { addSlashButtonLink } from '../../helper'
import { Link } from 'gatsby'

export const SupperPowerListenerBlock = () => {
	const blocks = [
		{
			icon: `<?xml version="1.0" encoding="utf-8"?>
            <svg width="76.5px" height="54.088623px" viewBox="0 0 76.5 54.088623" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <g id="people-group-duotone">
                <path d="M45.45 7.72695C45.45 3.45947 42.025 0 37.8 0C33.575 -4.28932e-16 30.15 3.45947 30.15 7.72695C30.15 11.9944 33.575 15.4539 37.8 15.4539C42.025 15.4539 45.45 11.9944 45.45 7.72695ZM30.15 27.7325L30.15 37.9587C28.9547 36.5944 28.2375 34.8075 28.2375 32.8516C28.2375 30.8957 28.9547 29.0968 30.15 27.7446L30.15 27.7325ZM45.45 37.9587L45.45 27.7325C46.6453 29.0968 47.3625 30.8837 47.3625 32.8396C47.3625 34.7954 46.6453 36.5823 45.45 37.9466L45.45 37.9587ZM45.45 45.0699C49.9683 42.8967 53.1 38.2484 53.1 32.8516C53.1 25.3782 47.1115 19.3295 39.7125 19.3295L35.8875 19.3295C28.4885 19.3295 22.5 25.3782 22.5 32.8516C22.5 38.2484 25.6317 42.8967 30.15 45.0699L30.15 50.2252C30.15 52.3622 31.8593 54.0887 33.975 54.0887L41.625 54.0887C43.7407 54.0887 45.45 52.3622 45.45 50.2252L45.45 45.0578L45.45 45.0699Z" id="Shape" fill="#20A8D8" stroke="none" />
                <path d="M15.3 15.4539C19.525 15.4539 22.95 11.9944 22.95 7.72695C22.95 3.45947 19.525 0 15.3 0C11.075 -4.28932e-16 7.65 3.45947 7.65 7.72695C7.65 11.9944 11.075 15.4539 15.3 15.4539ZM5.7375 32.8396C5.7375 30.8837 6.45469 29.0847 7.65 27.7325L7.65 37.9587C6.45469 36.5823 5.7375 34.7954 5.7375 32.8396ZM19.125 32.8396C19.125 28.2758 20.8702 24.1226 23.715 21.0197C21.7905 19.9331 19.5673 19.3174 17.2125 19.3174L13.3875 19.3174C5.98852 19.3174 0 25.3661 0 32.8396C0 38.2363 3.13172 42.8846 7.65 45.0578L7.65 50.2252C7.65 52.3622 9.3593 54.0887 11.475 54.0887L19.125 54.0887C21.2407 54.0887 22.95 52.3622 22.95 50.2252L22.95 45.0578L22.95 43.7659C20.5594 40.7838 19.125 36.9807 19.125 32.8396ZM53.55 45.0578L53.55 50.2252C53.55 52.3622 55.2593 54.0887 57.375 54.0887L65.025 54.0887C67.1407 54.0887 68.85 52.3622 68.85 50.2252L68.85 45.0578C73.3683 42.8846 76.5 38.2363 76.5 32.8396C76.5 25.3661 70.5115 19.3174 63.1125 19.3174L59.2875 19.3174C56.9327 19.3174 54.7095 19.9331 52.785 21.0197C55.6298 24.1226 57.375 28.2758 57.375 32.8396C57.375 36.9807 55.9406 40.7838 53.55 43.7659L53.55 45.0578ZM61.2 15.4539C65.425 15.4539 68.85 11.9944 68.85 7.72695C68.85 3.45947 65.425 0 61.2 0C56.975 -4.28932e-16 53.55 3.45947 53.55 7.72695C53.55 11.9944 56.975 15.4539 61.2 15.4539ZM70.7625 32.8396C70.7625 34.7954 70.0453 36.5823 68.85 37.9466L68.85 27.7325C70.0453 29.0968 70.7625 30.8837 70.7625 32.8396Z" id="Shape" fill="#7F8C9A" stroke="none" />
              </g>
            </svg>`,
			title: 'Marketing Leaders',
			description: 'Connect marketing activities with real revenue and pipeline. Prove (and improve) results.',
			button: { url: '/outcomes/marketing-leaders/', label: 'Learn More' }
		},
		{
			icon: `<?xml version="1.0" encoding="utf-8"?>
            <svg width="76.5px" height="54.088623px" viewBox="0 0 76.5 54.088623" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <g id="people-group-duotone">
                <path d="M45.45 7.72695C45.45 3.45947 42.025 0 37.8 0C33.575 -4.28932e-16 30.15 3.45947 30.15 7.72695C30.15 11.9944 33.575 15.4539 37.8 15.4539C42.025 15.4539 45.45 11.9944 45.45 7.72695ZM30.15 27.7325L30.15 37.9587C28.9547 36.5944 28.2375 34.8075 28.2375 32.8516C28.2375 30.8957 28.9547 29.0968 30.15 27.7446L30.15 27.7325ZM45.45 37.9587L45.45 27.7325C46.6453 29.0968 47.3625 30.8837 47.3625 32.8396C47.3625 34.7954 46.6453 36.5823 45.45 37.9466L45.45 37.9587ZM45.45 45.0699C49.9683 42.8967 53.1 38.2484 53.1 32.8516C53.1 25.3782 47.1115 19.3295 39.7125 19.3295L35.8875 19.3295C28.4885 19.3295 22.5 25.3782 22.5 32.8516C22.5 38.2484 25.6317 42.8967 30.15 45.0699L30.15 50.2252C30.15 52.3622 31.8593 54.0887 33.975 54.0887L41.625 54.0887C43.7407 54.0887 45.45 52.3622 45.45 50.2252L45.45 45.0578L45.45 45.0699Z" id="Shape" fill="#20A8D8" stroke="none" />
                <path d="M15.3 15.4539C19.525 15.4539 22.95 11.9944 22.95 7.72695C22.95 3.45947 19.525 0 15.3 0C11.075 -4.28932e-16 7.65 3.45947 7.65 7.72695C7.65 11.9944 11.075 15.4539 15.3 15.4539ZM5.7375 32.8396C5.7375 30.8837 6.45469 29.0847 7.65 27.7325L7.65 37.9587C6.45469 36.5823 5.7375 34.7954 5.7375 32.8396ZM19.125 32.8396C19.125 28.2758 20.8702 24.1226 23.715 21.0197C21.7905 19.9331 19.5673 19.3174 17.2125 19.3174L13.3875 19.3174C5.98852 19.3174 0 25.3661 0 32.8396C0 38.2363 3.13172 42.8846 7.65 45.0578L7.65 50.2252C7.65 52.3622 9.3593 54.0887 11.475 54.0887L19.125 54.0887C21.2407 54.0887 22.95 52.3622 22.95 50.2252L22.95 45.0578L22.95 43.7659C20.5594 40.7838 19.125 36.9807 19.125 32.8396ZM53.55 45.0578L53.55 50.2252C53.55 52.3622 55.2593 54.0887 57.375 54.0887L65.025 54.0887C67.1407 54.0887 68.85 52.3622 68.85 50.2252L68.85 45.0578C73.3683 42.8846 76.5 38.2363 76.5 32.8396C76.5 25.3661 70.5115 19.3174 63.1125 19.3174L59.2875 19.3174C56.9327 19.3174 54.7095 19.9331 52.785 21.0197C55.6298 24.1226 57.375 28.2758 57.375 32.8396C57.375 36.9807 55.9406 40.7838 53.55 43.7659L53.55 45.0578ZM61.2 15.4539C65.425 15.4539 68.85 11.9944 68.85 7.72695C68.85 3.45947 65.425 0 61.2 0C56.975 -4.28932e-16 53.55 3.45947 53.55 7.72695C53.55 11.9944 56.975 15.4539 61.2 15.4539ZM70.7625 32.8396C70.7625 34.7954 70.0453 36.5823 68.85 37.9466L68.85 27.7325C70.0453 29.0968 70.7625 30.8837 70.7625 32.8396Z" id="Shape" fill="#7F8C9A" stroke="none" />
              </g>
            </svg>`,
			title: 'Measurement Professionals',
			description: '<p>Implement faster, manage easier, and generate better insights & reporting.</p>',
			button: { url: '/outcomes/measurement-pros/', label: 'Learn More' }
		},
		{
			icon: `<?xml version="1.0" encoding="utf-8"?>
            <svg width="76.5px" height="54.088623px" viewBox="0 0 76.5 54.088623" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <g id="people-group-duotone">
                <path d="M45.45 7.72695C45.45 3.45947 42.025 0 37.8 0C33.575 -4.28932e-16 30.15 3.45947 30.15 7.72695C30.15 11.9944 33.575 15.4539 37.8 15.4539C42.025 15.4539 45.45 11.9944 45.45 7.72695ZM30.15 27.7325L30.15 37.9587C28.9547 36.5944 28.2375 34.8075 28.2375 32.8516C28.2375 30.8957 28.9547 29.0968 30.15 27.7446L30.15 27.7325ZM45.45 37.9587L45.45 27.7325C46.6453 29.0968 47.3625 30.8837 47.3625 32.8396C47.3625 34.7954 46.6453 36.5823 45.45 37.9466L45.45 37.9587ZM45.45 45.0699C49.9683 42.8967 53.1 38.2484 53.1 32.8516C53.1 25.3782 47.1115 19.3295 39.7125 19.3295L35.8875 19.3295C28.4885 19.3295 22.5 25.3782 22.5 32.8516C22.5 38.2484 25.6317 42.8967 30.15 45.0699L30.15 50.2252C30.15 52.3622 31.8593 54.0887 33.975 54.0887L41.625 54.0887C43.7407 54.0887 45.45 52.3622 45.45 50.2252L45.45 45.0578L45.45 45.0699Z" id="Shape" fill="#20A8D8" stroke="none" />
                <path d="M15.3 15.4539C19.525 15.4539 22.95 11.9944 22.95 7.72695C22.95 3.45947 19.525 0 15.3 0C11.075 -4.28932e-16 7.65 3.45947 7.65 7.72695C7.65 11.9944 11.075 15.4539 15.3 15.4539ZM5.7375 32.8396C5.7375 30.8837 6.45469 29.0847 7.65 27.7325L7.65 37.9587C6.45469 36.5823 5.7375 34.7954 5.7375 32.8396ZM19.125 32.8396C19.125 28.2758 20.8702 24.1226 23.715 21.0197C21.7905 19.9331 19.5673 19.3174 17.2125 19.3174L13.3875 19.3174C5.98852 19.3174 0 25.3661 0 32.8396C0 38.2363 3.13172 42.8846 7.65 45.0578L7.65 50.2252C7.65 52.3622 9.3593 54.0887 11.475 54.0887L19.125 54.0887C21.2407 54.0887 22.95 52.3622 22.95 50.2252L22.95 45.0578L22.95 43.7659C20.5594 40.7838 19.125 36.9807 19.125 32.8396ZM53.55 45.0578L53.55 50.2252C53.55 52.3622 55.2593 54.0887 57.375 54.0887L65.025 54.0887C67.1407 54.0887 68.85 52.3622 68.85 50.2252L68.85 45.0578C73.3683 42.8846 76.5 38.2363 76.5 32.8396C76.5 25.3661 70.5115 19.3174 63.1125 19.3174L59.2875 19.3174C56.9327 19.3174 54.7095 19.9331 52.785 21.0197C55.6298 24.1226 57.375 28.2758 57.375 32.8396C57.375 36.9807 55.9406 40.7838 53.55 43.7659L53.55 45.0578ZM61.2 15.4539C65.425 15.4539 68.85 11.9944 68.85 7.72695C68.85 3.45947 65.425 0 61.2 0C56.975 -4.28932e-16 53.55 3.45947 53.55 7.72695C53.55 11.9944 56.975 15.4539 61.2 15.4539ZM70.7625 32.8396C70.7625 34.7954 70.0453 36.5823 68.85 37.9466L68.85 27.7325C70.0453 29.0968 70.7625 30.8837 70.7625 32.8396Z" id="Shape" fill="#7F8C9A" stroke="none" />
              </g>
            </svg>`,
			title: 'Marketing Specialists',
			description: 'Drive better results by taking control of your data',
			button: { url: '/outcomes/marketing-specialists/', label: 'Learn More' }
		},
		{
			icon: `<?xml version="1.0" encoding="utf-8"?>
			<svg width="60.299805px" height="52.285645px" viewBox="0 0 60.299805 52.285645" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
			  <g id="Group">
				<path d="M0 7.46939C0 3.34955 3.3801 0 7.5375 0L52.7625 0C56.9199 0 60.3 3.34955 60.3 7.46939L60.3 44.8163C60.3 48.9362 56.9199 52.2857 52.7625 52.2857L7.5375 52.2857C3.3801 52.2857 0 48.9362 0 44.8163L0 7.46939ZM12.0482 13.9818C10.9883 15.1255 11.0707 16.8995 12.2249 17.9382L21.2581 26.1429L12.2249 34.3475C11.0707 35.3979 11 37.1602 12.0482 38.304C13.0964 39.4477 14.8866 39.5177 16.0407 38.479L27.347 28.2086C27.9359 27.6834 28.2656 26.9248 28.2656 26.1429C28.2656 25.3609 27.9359 24.614 27.347 24.0771L16.0407 13.8067C14.8866 12.7563 13.0964 12.838 12.0482 13.9818ZM29.2078 35.4796C27.6414 35.4796 26.3812 36.7284 26.3812 38.2806C26.3812 39.8328 27.6414 41.0816 29.2078 41.0816L46.1672 41.0816C47.7336 41.0816 48.9937 39.8328 48.9937 38.2806C48.9937 36.7284 47.7336 35.4796 46.1672 35.4796L29.2078 35.4796Z" id="Shape" fill="#7F8C9A" stroke="none" />
			  </g>
			</svg>`,
			title: 'Engineering & IT',
			description: 'Solve the "analytics" challenge while dedicating little to no resources. ',
			button: { url: '/outcomes/engineers-and-it/', label: 'Learn More' }
		},
	]
	return (
		<>
			<div className={`supper-power-listener-wrapper`}>
				<h3 className='supper-power-listener-headline'>ListenLayer gives you <span className='highlight'>“superpowers”</span></h3>
				{blocks && (
					<div className='supper-power-listener-list'>
						{blocks.map((block, index) => {
							const { icon, title, description, button } = block;
							if (icon && title && button?.url) {
								return (
									<div key={index} className="supper-power-listener-block">
										{icon && (
											<Link to={addSlashButtonLink(button.url)}>
												<div className="supper-power-listener-icon" dangerouslySetInnerHTML={{ __html: icon }} />
											</Link>
										)}
										<div className='supper-power-listener-content'>
											<Link to={addSlashButtonLink(button.url)}>
												<h4 className='supper-power-listener-title'>{title}</h4>
											</Link>
											{description &&
												<Link to={addSlashButtonLink(button.url)}>
													<div className='supper-power-listener-description' dangerouslySetInnerHTML={{ __html: description }} />
												</Link>
											}
											<Link to={addSlashButtonLink(button.url)} className='supper-power-listener-link'>
												{button.label}
												<span className='icon' dangerouslySetInnerHTML={{
													__html: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512" fill="currentColor">
															<path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
														</svg>`,
												}} />
											</Link>
										</div>
									</div>
								)
							}
							return (
								<Fragment key={index}></Fragment>
							)
						})}
					</div>
				)}
			</div>
		</>
	)
}

export default SupperPowerListenerBlock
