import React from 'react'
import { addSlashInternalLink } from '../../helper'

export const CTABlock = values => {
     const { description, pdfLink } = values

     return (
          <div className="cta-block">
               {description && <div className="description" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(description) }}></div>}
               {pdfLink && pdfLink.url && (
                    <a className="pdf-link" href={pdfLink.url} target="_blank" rel="noreferrer">
                         <img src="/images/pdf-icon.svg" alt="PDF Icon" />
                         <span>{pdfLink.text || ''}</span>
                    </a>
               )}
          </div>
     )
}

export default CTABlock
