import React, { useState, useEffect, useRef } from 'react'
import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty'
import { addSlashInternalLink } from '../../helper'

const MultipleStepBlock = ({ values }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [fixed, setFixed] = useState(false);
    const sectionMulti = useRef(null)
    const stepRefs = useRef([]);
    const videoRefs = useRef([]);
    let currentSection = values.sections[currentImage];

    const playVideo = () => {
        if (window.innerWidth > 1024) {
            const video = document.getElementById(`video-${currentImage}`)
            video.currentTime = 0
            video.play()
        }
    }

    const handleHover = (index) => {
        if (window.innerWidth > 1024) {
            setCurrentImage(index)
        }
    }

    const handleClickScroll = (index) => {
        if (window.innerWidth < 481) {
            const selected = document.getElementsByClassName("step")[index]
            const selectedOffsetTop = selected.offsetParent.offsetTop + selected.offsetTop
            window.scrollTo({
                top: selectedOffsetTop,
                behavior: 'smooth',
            })
        }
    }

    const scrollVideoFixed = () => {
        if (window.innerWidth < 481) {
            let videoCurrent = {}
            let indexCurrent = ''
            const currentScroll = window.pageYOffset
            videoRefs.current.some((video, index) => {
                const stepEl = document.getElementsByClassName("step")[index]
                const stepOffsetTop = stepEl?.offsetParent?.offsetTop + stepEl?.offsetTop
                const stepOffsetBottom = stepOffsetTop + stepEl.offsetHeight
                if (currentScroll >= stepOffsetTop && currentScroll <= stepOffsetBottom) {
                    setCurrentImage(index)
                    indexCurrent = index
                    videoCurrent = video
                    return true;
                }
                return false
            })

            if (!isEmpty(videoCurrent)) {
                setFixed(true)
                const videoHeight = videoCurrent.offsetHeight
                if (videoCurrent.className.indexOf('fixed') >= 0 && videoRefs.current.length > indexCurrent + 1) {
                    const stepElNext = document.getElementsByClassName("step")[indexCurrent + 1]
                    const stepNextOffsetTop = stepElNext.offsetParent.offsetTop + stepElNext.offsetTop
                    const videoFixedBottom = videoHeight + currentScroll
                    const fixedFadeOut = stepNextOffsetTop - videoFixedBottom
                    let opacity = 1;
                    if (fixedFadeOut <= 35) {
                        opacity = fixedFadeOut / 35;
                    }
                    videoCurrent.style.opacity = opacity
                    if (opacity <= 0) {
                        videoCurrent.style.visibility = "hidden"
                    } else {
                        videoCurrent.style.visibility = ""
                    }
                } else if (indexCurrent + 1 === videoRefs.current.length) {
                    const topSectionMulti = sectionMulti?.current?.offsetParent?.offsetTop
                    const lastVideoHeigth = fixed ? videoHeight + currentScroll : videoHeight + currentScroll + 36
                    let bottomSectionMulti = topSectionMulti + sectionMulti.current.offsetHeight;
                    if (lastVideoHeigth >= bottomSectionMulti) setFixed(false)
                }
            } else {
                setFixed(false)
            }
        }
    }

    const throttleScroll = throttle(scrollVideoFixed, 0);

    const onScroll = () => {
        if (typeof window !== 'undefined' && window) {
            window.addEventListener('scroll', throttleScroll);
        }

        return () => window.removeEventListener('scroll', throttleScroll); // Cleanup
    }

    useEffect(onScroll, [fixed]);

    const handleOnVideoEnded = (index) => {
        if (window.innerWidth > 1024 && index === currentImage) {
            if (currentImage < values.sections.length - 1) {
                setCurrentImage(currentImage + 1)
            } else {
                setCurrentImage(0)
            }
        }
    }

    useEffect(playVideo, [currentImage])

    return (
        (values.sections && (
            <div className="multiple-wrapper" ref={sectionMulti}>
                <div className="is-tablet-mobile">
                    {
                        values.sections.map((section, index) => {
                            return (
                                <div key={index} className="step">

                                    <div className="row">
                                        <div className="custom-col-video">
                                            <div className="video-wrapper">
                                                <video className={`tablet ${currentImage === index && fixed ? 'video-holder' : ''}`} autoPlay muted loop playsInline>
                                                    <source src={section.videoDesktop.mediaItemUrl} type="video/mp4" className="video-auto-play" />
                                                </video>
                                                <video className={`mobile ${currentImage === index && fixed ? 'fixed' : ''}`} autoPlay muted loop playsInline ref={el => videoRefs.current[index] = el}>
                                                    <source src={section.videoMobile.mediaItemUrl} type="video/mp4" className="video-auto-play" />
                                                </video>
                                            </div>
                                        </div>
                                        <div className="custom-col-content-step">
                                            <div className="wrapper-content">
                                                <div className={`title ${currentImage === index ? 'active' : ''}`}  >
                                                    <button onClick={() => handleClickScroll(index)} onMouseOver={() => handleHover(index)} onFocus={() => void 0} >{section.headline}{index === currentImage ? <img src="/images/arrow-right.svg" alt="Arrow Right Icon" /> : ''}</button>
                                                </div>
                                                <div className="text" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(section.text) }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="is-desktop">
                    <div className="row">
                        <div className="custom-col-content-step">
                            {
                                values.sections.map((section, index) => {
                                    return (
                                        <div tabIndex="-1" role="button" aria-label="step" onMouseOver={() => handleHover(index)} onFocus={() => void 0} key={index} className="step" ref={el => stepRefs.current[index] = el}>
                                            <div className={`title ${index === currentImage ? 'active' : ''}`}  >
                                                <button >{section.headline}<img src="/images/arrow-right.svg" alt="Arrow Right Icon" /></button>
                                            </div>
                                            <div className="text" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(section.text) }}></div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        {
                            currentSection && (
                                <div className="custom-col-video">
                                    <div className="video-wrapper">
                                        {
                                            values.sections.map((section, index) => {
                                                return (
                                                    <video id={`video-${index}`} key={index} className={index === currentImage ? 'd-block' : 'd-none'} onEnded={() => handleOnVideoEnded(index)} playsInline muted>
                                                        <source src={section.videoDesktop.mediaItemUrl} type="video/mp4" className="video-auto-play" />
                                                    </video>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        ))
    )
}

export default MultipleStepBlock
