import React from 'react'
import { Link } from 'gatsby'
import { imageSizeUrl } from '../../helper'

const UseCaseBlock = ({ imageExcerpt, title, excerpt, usecaseSlug }) => {
    const image = imageExcerpt ? imageSizeUrl('medium_large', imageExcerpt.mediaDetails.sizes, imageExcerpt.mediaItemUrl) : `/images/logo-dark.png`
    return (
        <div className="card-group use-case-item">
            <div className="card">
                <div className="card-img-top use-case-image">
                    <Link to={usecaseSlug}>
                        <div className="image" style={{ backgroundImage: `url('${image}')` }}></div>
                    </Link>
                </div>
                <div className="use-case-content card-body">
                    <h3 className="card-title"><Link to={usecaseSlug}>{title}</Link></h3>
                    <p className="card-text">{excerpt}</p>
                </div>
            </div>
        </div>
    )
}

export default UseCaseBlock
