import React from 'react'

const MissionBlock = ({ headline, ourMissions, ourGoals }) => {
    return (
        <div className="card-wrapper">
            <div className="our-goals-wrapper">
                {
                    headline && (
                        <div className="title">
                            <h3>{headline}</h3>
                        </div>
                    )
                }
                {
                    ourMissions && (
                        <div className="our-mission">
                            <h4>{ourMissions.headline}</h4>
                            <p>{ourMissions.subHeadline}</p>
                        </div>
                    )
                }
                {
                    ourGoals && (
                        <div className="our-goals">
                            <h4>{ourGoals.headline}</h4>
                            <div className="our-goals-row">
                                <div className="our-goals-left">
                                    <h5>{ourGoals.goalsWebsite.goalNumber}</h5>
                                    <p>{ourGoals.goalsWebsite.goalDescription}</p>
                                </div>
                                <div className="our-goals-right">
                                    <h5>{ourGoals.goalKids.goalNumber}</h5>
                                    <p>{ourGoals.goalKids.goalDescription}</p>
                                </div>
                            </div>
                        </div>
                    )
                }

            </div>

            {/* <div className="col-lg-6">
                <div className="browse-all-apps-wrapper">
                    <div className="browse-all-apps">
                        <div className="app-row">
                            <div className="app-col">
                                <div className="app-item">
                                    <div className="logo">
                                        <img src="/images/sales-force.svg" alt="Sales Force Icon" />
                                    </div>
                                    <div className="text">
                                        <span>Web to Lead Forms</span>
                                    </div>
                                </div>
                            </div>
                            <div className="app-col">
                                <div className="app-item">
                                    <div className="logo">
                                        <img src="/images/sales-force.svg" alt="Sales Force Icon" />
                                    </div>
                                    <div className="text">
                                        <span>Web to Lead Forms</span>
                                    </div>
                                </div>
                            </div>
                            <div className="app-col">
                                <div className="app-item">
                                    <div className="logo">
                                        <img src="/images/sales-force.svg" alt="Sales Force Icon" />
                                    </div>
                                    <div className="text">
                                        <span>Web to Lead Forms</span>
                                    </div>
                                </div>
                            </div>
                            <div className="app-col">
                                <div className="app-item">
                                    <div className="logo">
                                        <img src="/images/sales-force.svg" alt="Sales Force Icon" />
                                    </div>
                                    <div className="text">
                                        <span>Web to Lead Forms</span>
                                    </div>
                                </div>
                            </div>
                            <div className="app-col">
                                <div className="app-item">
                                    <div className="logo">
                                        <img src="/images/sales-force.svg" alt="Sales Force Icon" />
                                    </div>
                                    <div className="text">
                                        <span>Web to Lead Forms</span>
                                    </div>
                                </div>
                            </div>
                            <div className="app-col">
                                <div className="app-item">
                                    <div className="logo">
                                        <img src="/images/sales-force.svg" alt="Sales Force Icon" />
                                    </div>
                                    <div className="text">
                                        <span>Web to Lead Forms</span>
                                    </div>
                                </div>
                            </div>
                            <div className="app-col">
                                <div className="all-app-item">
                                    <div className="text">
                                        <span>Browse All Apps</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default MissionBlock
