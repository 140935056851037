import React from 'react'
import { addSlashInternalLink } from '../../helper'

const HeadlineTypeBlock = ({ headline, headlineType }) => {
    let headlineContent = 'test'
    switch (headlineType) {
        case 'H1':
            headlineContent = <h1 dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headline) }}></h1>
            break;
        case 'H2':
            headlineContent = <h2 dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headline) }}></h2>
            break;
        case 'H3':
            headlineContent = <h3 dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headline) }}></h3>
            break;
        case 'H4':
            headlineContent = <h4 dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headline) }}></h4>
            break;
        case 'H5':
            headlineContent = <h5 dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headline) }}></h5>
            break;
        default:
            headlineContent = <h6 dangerouslySetInnerHTML={{ __html: addSlashInternalLink(headline) }}></h6>
            break;
    }

    return (
        <>
            {headlineContent}
        </>
    )

}
export default HeadlineTypeBlock