import React from 'react'
import { alignContent } from '../../helper'
import { addSlashInternalLink } from '../../helper'

export const NewHeroBlock = ({ positioning, h1, subText, width }) => {
    const widthClass = width === 'Medium' ? 'width-medium' : (
        width === 'Large' ? 'width-large' : ''
    )
    return (
        <>
            {
                h1 && (
                    <div className={`new-hero-wrapper ${alignContent(positioning)} ${widthClass}`}>
                        {h1 && <h1 dangerouslySetInnerHTML={{ __html: h1 }}></h1>}
                        {subText && <div className="sub-text" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(subText) }}></div>}
                    </div>
                )
            }
        </>

    )
}

export default NewHeroBlock
