import React, { Fragment } from 'react'

export const IntroCapabilitiesBlock = () => {
     const blocks = [
          {
               icon: `<?xml version="1.0" encoding="utf-8"?>
            <svg width="44px" height="44px" viewBox="0 0 44 44" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <g id="Group-13">
                <path d="M37.125 0C35.982 0 35.0625 0.919576 35.0625 2.0626L35.0625 4.81274L32.3125 4.81274C31.1695 4.81274 30.25 5.73231 30.25 6.87534C30.25 8.01836 31.1695 8.93794 32.3125 8.93794L35.0625 8.93794L35.0625 11.6881C35.0625 12.8311 35.982 13.7507 37.125 13.7507C38.268 13.7507 39.1875 12.8311 39.1875 11.6881L39.1875 8.93794L41.9375 8.93794C43.0805 8.93794 44 8.01836 44 6.87534C44 5.73231 43.0805 4.81274 41.9375 4.81274L39.1875 4.81274L39.1875 2.0626C39.1875 0.919576 38.268 0 37.125 0Z" id="Path" fill="#73818F" stroke="none" />
                <path d="M24.0195 11.4474C22.7391 10.8544 21.2609 10.8544 19.9805 11.4474L1.19453 20.1275C0.464063 20.4627 0 21.1932 0 22.0011C0 22.8089 0.464063 23.5394 1.19453 23.8746L19.9805 32.5547C21.2609 33.1477 22.7391 33.1477 24.0195 32.5547L42.8055 23.8746C43.5359 23.5394 44 22.8089 44 22.0011C44 21.1932 43.5359 20.4627 42.8055 20.1275L24.0195 11.4474Z" id="Path" fill="#FFC107" stroke="none" />
                <path d="M25.1711 35.0556C23.1602 35.9838 20.8398 35.9838 18.8289 35.0556L5.76641 29.0225L1.19453 31.1281C0.464063 31.4633 0 32.1938 0 33.0016C0 33.8095 0.464063 34.54 1.19453 34.8751L19.9805 43.5553C21.2609 44.1482 22.7391 44.1482 24.0195 43.5553L42.8055 34.8751C43.5359 34.54 44 33.8095 44 33.0016C44 32.1938 43.5359 31.4633 42.8055 31.1281L38.2336 29.0139L25.1711 35.047L25.1711 35.0556Z" id="Path" fill="#20A8D8" stroke="none" />
              </g>
            </svg>`,
               title: 'Automate Your Data',
               description:
                    '<p>Collect marketing & website data faster, more accurately, and with less effort using our library of prebuilt Listeners. No more inaccurate tracking schemes and waiting on IT.</p>',
          },
          {
               icon: `<?xml version="1.0" encoding="utf-8"?>
            <svg width="58px" height="46px" viewBox="0 0 58 46" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <g id="chart-network-sharp-regular">
                <path d="M26.1 0L14.5 0L14.5 11.5L21.7319 11.5L23.6531 16.2707C20.4903 18.1844 18.2156 21.4008 17.5812 25.1563L11.6 25.1563L11.6 21.5625L0 21.5625L0 33.0625L11.6 33.0625L11.6 29.4688L17.5812 29.4688C18.6144 35.5871 23.9884 40.25 30.45 40.25C34.4194 40.25 37.9719 38.498 40.3644 35.7219L46.4 39.3156L46.4 46L58 46L58 34.5L46.7625 34.5L42.6119 32.0293C43.1919 30.5648 43.5091 28.9746 43.5091 27.3125C43.5091 25.2281 43.0106 23.2516 42.1225 21.5086L47.9678 17.25L58 17.25L58 5.75L46.4 5.75L46.4 13.0453L39.5397 18.0316C37.1925 15.7676 33.9844 14.375 30.45 14.375C29.4984 14.375 28.5831 14.4738 27.6859 14.6625L26.1 10.7273L26.1 0ZM30.45 18.6875C33.5582 18.6875 36.4303 20.3314 37.9844 23C39.5385 25.6686 39.5385 28.9564 37.9844 31.625C36.4303 34.2936 33.5582 35.9375 30.45 35.9375C25.6451 35.9375 21.75 32.076 21.75 27.3125C21.75 22.549 25.6451 18.6875 30.45 18.6875L30.45 18.6875Z" id="Shape" fill="#FFC107" stroke="none" />
              </g>
            </svg>`,
               title: 'Distribute Your Data',
               description:
                    '<p>Power centralized conversion tracking & distribute data to the analytics tools and advertising platforms you use. Now  everything comes from an accurate, centralized source of truth.</p>',
          },
          {
               icon: `<?xml version="1.0" encoding="utf-8"?>
            <svg width="48px" height="51px" viewBox="0 0 48 51" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <g id="shield-check-sharp-solid">
                <path d="M23.9967 0L25.1565 0.448242L45.9043 8.4668L47.8241 9.20391L47.9441 11.2459C48.2341 16.2264 47.4542 23.8266 44.2145 31.2574C40.9449 38.748 35.1055 46.1988 25.2765 50.4422L23.9967 51L22.7268 50.4521C12.8878 46.2088 7.04845 38.768 3.7888 31.2674C0.539144 23.8365 -0.240773 16.2264 0.0591949 11.2559L0.179182 9.21387L2.08898 8.4668L22.8368 0.448242L23.9967 0ZM35.2955 20.8184L36.9953 19.125L33.5956 15.7482L31.8958 17.4416L20.797 28.4982L16.0975 23.8166L14.3977 22.1232L11.008 25.5L12.7079 27.1934L19.1072 33.5684L20.807 35.2617L22.5068 33.5684L35.2955 20.8184Z" id="Shape" fill="#4DBD74" stroke="none" />
              </g>
            </svg>`,
               title: 'Remain Compliant',
               description:
                    '<p>Remain compliant with centralized consent & privacy management. Replace (or augment) 3rd party Consent Management Platforms to centralize how you identify, track, and target users.</p>',
          },
          {
               icon: `<?xml version="1.0" encoding="utf-8"?>
            <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <g id="chart-simple-sharp-solid">
                <path d="M17.1429 0L30.8571 0L30.8571 48L17.1429 48L17.1429 0ZM0 20.5714L13.7143 20.5714L13.7143 48L0 48L0 20.5714ZM48 6.85714L48 48L34.2857 48L34.2857 6.85714L48 6.85714Z" id="Shape" fill="#20A8D8" stroke="none" />
              </g>
            </svg>`,
               title: 'Gain Insights',
               description:
                    '<p>Obtain a new perspective on marketing. Generate behavioral insights (like Google Analytics) that can be tied to real people, companies, and revenue for a full view of marketing performance and revenue attribution.</p>',
          },
     ]
     return (
          <>
               {blocks && (
                    <div className={`capability-wrapper`}>
                         {blocks.map((block, index) => {
                              const { icon, title, description } = block
                              if ((icon, title)) {
                                   return (
                                        <div key={index} className="capability-block">
                                             {icon && <div className="capability-icon" dangerouslySetInnerHTML={{ __html: icon }} />}
                                             {title && <h4 className="capability-title">{title}</h4>}
                                             {description && (
                                                  <div className="capability-description" dangerouslySetInnerHTML={{ __html: description }} />
                                             )}
                                        </div>
                                   )
                              }
                              return <Fragment key={index}></Fragment>
                         })}
                    </div>
               )}
          </>
     )
}

export default IntroCapabilitiesBlock
