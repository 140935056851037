import React from 'react'
import { navigate } from '@reach/router'
// import { useDispatch } from 'react-redux'
// import { setOpenPopupSignUp } from '../../actions/common'
const data = [
     {
          title: 'Built for B2B & B2C',
          description:
               'Power measurement and attribution for long and short sales cycles... everything from eCommerce & lead generation to B2B pipeline and revenue captured in Salesforce.com.',
     },
     {
          title: 'Replace Tools & Centralize',
          description:
               'On average, marketing data is fragmented across 6 or more platforms. Centralize your data strategy for a single source of truth and replace or augment tools like Google Analytics, OneTrust, Cookiebot, and Hotjar!',
     },
     {
          title: 'Reveal Real People & Companies',
          description:
               'Gone are the days of anonymous "conversions" and behavioral analytics. Identify real people that have interacted with your website and reveal the businesses they work for.',
     },
     {
          title: 'Prove Results & Improve Performance',
          description:
               'Marketers are expected to prove results, down to revenue! ListenLayer allows you to connect external data sources and ties our full suite of (unanonymized) analytics data to your pipeline and revenue.',
     },
     {
          title: 'Achieve Compliance',
          description:
               'Centralize your web and marketing compliance into a single tool. ListenLayer is compatible with GDPR, CCPA/CPRA, other privacy laws, and is one of the only HIPAA compliant analytics and tagging tools. You can even replace your current CMP!',
     },
]

const CardBlock = ({ props }) => {
     // const dispatch = useDispatch()

     const onClickGetStarted = () => {
          navigate(`${process.env.GATSBY_APP_PATH}/register/?navigate=true`)
          // window.location.replace(`${process.env.GATSBY_APP_PATH}/register`)
     }

     return (
          <>
               <div className="section-card">
                    {data.map(item => {
                         return (
                              <div className="section-card-box">
                                   <h2>{item.title}</h2>
                                   <p>{item.description}</p>
                              </div>
                         )
                    })}
               </div>

               <section>
                    <div className="section-ready-how">
                         <div className=" section-ready-how-box">
                              <div className="title">
                                   <h3>Ready to Dive In?</h3>
                                   <p>See how easy (and powerful) ListenLayer is by opening an account today or requesting a demo.</p>
                              </div>
                              <div className="button-wrapper">
                                   <button onClick={() => onClickGetStarted()}>Get started</button>
                              </div>
                         </div>
                    </div>
               </section>
          </>
     )
}

export default CardBlock
