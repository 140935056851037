import React from 'react'
import { addSlashInternalLink } from '../../helper'

const HowItWorkBlock = ({ firstContent, actions, secondContent }) => {
     return (
          <div className="listen-activities-wrapper">
               <div className="first-content">
                    {firstContent.headline && (
                         <div className="title">
                              <h3 dangerouslySetInnerHTML={{ __html: firstContent.headline }}></h3>
                         </div>
                    )}
                    {firstContent.body && (
                         <div className="content" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(firstContent.body) }}></div>
                    )}
                    <div className="first-line-arrow">
                         <img src="/images/line-arrow.png" alt="Line Arrow" />
                    </div>
               </div>
               <div className="activity-list">
                    <div className="row">
                         {actions &&
                              actions.map((action, index) => {
                                   return (
                                        <div className="col-lg-4" key={index}>
                                             <div className={`activity-item ${!action?.iconSvg && 'item-non-icon'}`}>
                                                  {index === 0 ? (
                                                       <>
                                                            <img
                                                                 className="activity-line-first"
                                                                 src="/images/line-arrow-top.png"
                                                                 alt="Activity Line First"
                                                            />
                                                            {/* <img className="activity-line-first-mobile" src="/images/line-events.svg" alt="Activity Line Events" /> */}
                                                       </>
                                                  ) : (
                                                       <></>
                                                  )}
                                                  {index === 1 ? (
                                                       <>
                                                            <img
                                                                 className="activity-line-second"
                                                                 src="/images/line-arrow-bottom.png"
                                                                 alt="Activity Line Second"
                                                            />
                                                            <img
                                                                 className="line-arrow-first is-mobile"
                                                                 src="/images/arrow-line.png"
                                                                 alt="Line Arrow First"
                                                            />
                                                       </>
                                                  ) : (
                                                       <></>
                                                  )}
                                                  {index === 2 ? (
                                                       <>
                                                            <img
                                                                 className="line-arrow-second is-mobile"
                                                                 src="/images/arrow-line.png"
                                                                 alt="Line Arrow Second"
                                                            />
                                                            <img
                                                                 className="line-arrow-third is-mobile"
                                                                 src="/images/arrow-line.png"
                                                                 alt="Line Arrow Third"
                                                            />
                                                       </>
                                                  ) : (
                                                       <></>
                                                  )}
                                                  {action.iconSvg && (
                                                       <div className="box-icon">
                                                            <div className="icon" dangerouslySetInnerHTML={{ __html: action.iconSvg }}></div>
                                                       </div>
                                                  )}
                                                  <div className="action">
                                                       {action.title && (
                                                            <div className="title">
                                                                 <p>{action.title}</p>
                                                            </div>
                                                       )}
                                                       {action.detail && (
                                                            <div className="infor">
                                                                 <p>{action.detail}</p>
                                                            </div>
                                                       )}
                                                  </div>
                                             </div>
                                        </div>
                                   )
                              })}
                    </div>
               </div>
               <div className="second-content">
                    <div className="second-line-arrow">
                         <img src="/images/line-arrow-second.png" alt="Line Arrow Second" />
                    </div>
                    {secondContent.headline && (
                         <div className="title">
                              <h3 dangerouslySetInnerHTML={{ __html: secondContent.headline }}></h3>
                         </div>
                    )}
                    {secondContent.body && (
                         <div className="content" dangerouslySetInnerHTML={{ __html: addSlashInternalLink(secondContent.body) }}></div>
                    )}
               </div>
          </div>
     )
}

export default HowItWorkBlock
