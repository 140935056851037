import React from 'react'
// import SignUpStartBlock from './SignUpStartBlock'
import { useStaticQuery, graphql } from 'gatsby'
import { navigate } from '@reach/router'

const OldCreateAccountBlock = ({ classForm, customClass, headline }) => {
     const accountFieldsTextQl = useStaticQuery(graphql`
          query AccountFieldsText {
               wp {
                    acfOptionsGeneralSiteSettings {
                         cfGeneralSiteSettings {
                              headline
                              subText
                         }
                    }
               }
          }
     `)

     const accountFieldsText = accountFieldsTextQl.wp.acfOptionsGeneralSiteSettings.cfGeneralSiteSettings

     const onClickGetStarted = () => {
          navigate(`${process.env.GATSBY_APP_PATH}/register/?navigate=true`)
          // window.location.replace(`${process.env.GATSBY_APP_PATH}/register`)
     }

     return (
          <>
               <div className={`create-account-wrapper ${customClass ? customClass : ''}`}>
                    <div className="row">
                         {accountFieldsText.headline && (
                              <div className="left-wrapper">
                                   <div className="headline">
                                        {headline ? <h2>{headline}</h2> : accountFieldsText.headline && <h2>{accountFieldsText.headline}</h2>}
                                        {accountFieldsText.subText && <p>{accountFieldsText.subText}</p>}
                                   </div>
                              </div>
                         )}
                         {/* <div className="right-wrapper">
                         <div className={classForm}>
                              <SignUpStartBlock />
                         </div>
                    </div> */}
                         <div className="right-wrapper">
                              <div className={classForm}>
                                   <div className="button-wrapper">
                                        <button onClick={onClickGetStarted}>Get started</button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

const CreateAccountBlock = ({ createAccountType, customClass, test }) => {
     let content = ''
     switch (createAccountType) {
          case 'Smaller Version':
               let headline = 'Ready to get started?'
               content = <OldCreateAccountBlock classForm="small-ver" customClass={customClass} headline={headline} />
               break
          default:
               content = <OldCreateAccountBlock classForm="standard-ver" customClass={customClass} />
     }
     return <>{content}</>
}

export default CreateAccountBlock
